import BaseBean from "@/utils/BaseBean";
import {useRoute} from "vue-router";
import {nextTick} from 'vue'
export interface IListEngineDataObj {
    utilInst:any
    refMap:Map<string,any>
    mainComp:any
    fullscreenLoading:boolean//进入ListEngine的时候显示遮罩层，这个是局部遮罩，如果用指令方式就是整个页面都会遮罩
    exportType:number//导出类别
    exportExcelVisible: boolean//导出弹出框的控制/影藏控制字段
    backData: any//listEngine查询后台之后返回的结果
    collapseTitle:string
    queryCondition:Array<string>
    queryHeight:number//查询条件折叠面板标题的高度，在显示之前，会重新计算该值
    queryConditionHeight: number//查询条件的高度，默认是0，如果要显示查询条件，需要在显示之前改变这个高度
    listButtons: Array<any>//列表的操作按钮
    listParam: any   //listEngine的默认参数，该对象将会把从父类收到的参数和自己的默认参数合并，然后传递给gridTable控件
    otherParams:any
}
export default class ListEngineUtil extends BaseBean{
    route = useRoute();
    public dataObj:IListEngineDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IListEngineDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //初始化列表引擎，合并业务组件传过来的参数
    public initListEngineParams():void{
        let pageParams=this.context.attrs.pageList||{};
        //设置表格标题(默认取路由，也就是后台menu配置的标题，路由没有则看传给ListEngine的参数里面有没有title，再没有的话，则取传给ListEngine的卡片组件标题，还没有的话，就没有标题了)
        let title=this.route.meta.title;
        if(pageParams.gridTitle)title=pageParams.gridTitle;
        else if(pageParams.modelComp)title=pageParams.modelComp.title;
        Object.assign(this.dataObj.listParam, pageParams,{gridTitle:title});
        if(!this.dataObj.listParam.expandQuery)this.dataObj.queryCondition=[];//如果查询条件是收起来的，则需要把这句话加上，否则折叠展开有问题
    }
    //计算查询条件的高度、构建查询条件和列表页面的列信息
    //刚开始的时候，我把这段代码的调用逻辑必须放在onBeforeMount里面，因为在Condition子组件的onMounted里面用到conditionList，
    //要想赶在它使用之前把conditionList初始化好，就必须放在onBeforeMount里面了，因为父子组件生命周期函数的执行顺序是：
    //子组件onBeforeMount>父组件onBeforeMount>子组件onMounted>父组件onMounted
    //后来我改了一下Condition子组件，让它监听了父组件传给它的conditionList，当其值改变之后，会重新给它的一个变量赋值，
    //而子组件Condition真正使用的是这个变量，于是更新界面，所以现在下面代码在onBeforeMount里面还是onMounted里面执行都可以了。
    public async buildPage(res:any):Promise<void>{
        let conditionRowNum = 0;//计算查询条件内容高度
        let rowHeight=39;//el-row默认是small的高度
        if (this.dataObj.listParam.isShowQueryParam) {//如果要展示查询条件，那么计算总共查询条件高度
            if(this.context.slots.queryParams){//模块传入查询条件
                let queryParams=this.context.slots.queryParams();
                //有可能有些查询条件不显示，比如不显示机构、部门、人员的时候，在部门、人员、账号等那里又有机构、部门、人员的查询条件
                //这个时候即便用了v-if来判断，但是这里得到的slot仍然有，但是它的props为null，没有加v-if的条目，props为myRow，
                //所以要记住，列表的查询条件一定要放到样式为myRow，否则会有一片空白显示在查询条件那里，因为这里计算高度的时候会多算
                queryParams.forEach((item:any)=>{if(item.props)conditionRowNum++;})//计算查询条件行数
            }else{//后台传入查询条件（页面传入的优先）
                if(this.dataObj.listParam.isBuildPageFromBack && (this.dataObj.otherParams.conditions.length==0 || this.dataObj.otherParams.componentLoadFlag)){
                    this.dataObj.otherParams.conditions=res.pageInfo.conditions;
                    if(this.dataObj.otherParams.conditions.length>0){//计算查询条件行数
                        if(this.dataObj.otherParams.conditions.length%2==0)conditionRowNum=this.dataObj.otherParams.conditions.length/2;
                        else conditionRowNum=Math.floor(this.dataObj.otherParams.conditions.length/2)+1;
                        this.dataObj.otherParams.querySlots=this.dataObj.otherParams.conditions.map((item:any)=>{//查询条件插槽名称
                            return item.javaName;
                        });
                    }
                    if(this.context.slots.extendSlot){
                        let extendSlot=this.context.slots.extendSlot();
                        conditionRowNum=conditionRowNum+extendSlot.length;
                    }
                }
            }
        }
        if(conditionRowNum==0){//如果说了要查询条件，但是又没有准备查询条件，则直接当作不要查询条件处理
            this.dataObj.listParam.isShowQueryParam=false;
            this.dataObj.queryHeight=0;
        }else this.dataObj.queryConditionHeight = conditionRowNum * rowHeight;
    }
    //请求后台，为表格数据赋值(在列表引擎页面不让表格自动加载了，因为如果一个页面有多个表格，就会到后台查询多次，假如把请求数据放到列表引擎，就可以只请求一次，然后把结果给各个表格)
    public async loadPageData():Promise<void>{
        //准备查询参数
        let queryParam=this.dataObj.listParam.queryParam;
        queryParam.pageSize = this.dataObj.refMap.get('tbRef').params.pagerParams.pageSize;
        queryParam.currentPage = this.dataObj.refMap.get('tbRef').currentPage;
        queryParam.canPage = this.dataObj.refMap.get('tbRef').params.canPage;
        queryParam.isBuildPageFromBack = this.dataObj.listParam.isBuildPageFromBack;
        let res = await this.utils.Api.postRequest({url: this.dataObj.listParam.modelMethod, params: queryParam});
        if(this.dataObj.listParam.isBuildPageFromBack && res.pageInfo){
            res.pageInfo=JSON.parse(res.pageInfo);//如果由后台构建页面信息，由于后台返回回来的是json字符串形式，所以需要转换一下
            //这里最好判断一下是否需要触发自定义事件处理res.pageInfo
            await this.utils.Event.dispatchEvent('/'+this.dataObj.listParam.modelMethod.split('/')[1]+'_dealPageInfo',
                {pageInfo:res.pageInfo,engineInst:this.proxy});
            res=await this.props.formatPageInfo(res);//抛给页面模块再格式化一下数据
            //避免重复设置值，这里判断一下
            if(this.dataObj.listParam.columnList.length==0 || this.dataObj.otherParams.componentLoadFlag){
                this.dataObj.listParam.columnList=res.pageInfo.columns;
                this.dataObj.refMap.get('tbRef').setGridColumns(res.pageInfo.columns);
            }
        }
        await this.buildPage(res);
        if(!this.callEvent('afterPageData',res)){//如果业务没有定义表格加载完毕事件，则列表引擎自己处理
            this.dataObj.refMap.get('tbRef').setTbData(res.rows);
            this.dataObj.refMap.get('tbRef').setTbDataTotal(res.total);
            this.props.gridLoaded(res);
        }
        this.dataObj.backData = res;//存储后台返回的数据
        this.dataObj.listButtons = res.buttons;//为列表头部的按钮赋值
        this.dataObj.fullscreenLoading=false;//关闭正在加载效果
    }

    //如果弹出框里面包含的是列表引擎，那么在弹出框打开的时候，在dialogUtil那里会调用下面这个方法，目的就是为了重新计算一下表格的高度，
    //否则在弹出框里面的列表显示不正确，可能看不到分页按钮等等，因为弹出框可能不是全屏展示，列表引擎放到里面显示的内容空间有限，
    //所以这里需要根据弹出框的内容空间来计算表格的最大高度。放心，在调用计算表格高度之前，下方这个方法就会被调用
    public setSpeHeight(height:number,reCalFlag:boolean):void{
        this.dataObj.otherParams.baseCalHeight=height;
        this.setGridTableContentMaxHeight(height);
    }

    //折叠面板事件，重新计算表格的最大高度
    public handleChange(val:any):void{
        if (val.length == 1) { //表示展开
            this.dataObj.collapseTitle=this.proxy.$t('listEngine.queryOpenTitle');
            this.dataObj.queryHeight=this.dataObj.queryConditionHeight+this.utils.Const.listEngineCollapseHeight;
        }else{
            this.dataObj.collapseTitle=this.proxy.$t('listEngine.queryFoldTitle');
            this.dataObj.queryHeight=this.utils.Const.listEngineCollapseHeight;
        }
        this.setGridTableContentMaxHeight(this.dataObj.otherParams.baseCalHeight);
    }
    //重新为表格赋值最大高度，变高则height为负数
    public setGridTableContentMaxHeight(height:number=window.innerHeight):void{
        nextTick(()=>{//必须放在nextTick里面，否则弹出框包含列表的时候，下方获取到的this为undefined
            let showTop:boolean=localStorage.getItem('showTop')?localStorage.getItem('showTop')=='true':true;
            if(!showTop)this.utils.Const.topHeight=0;
            let maxTbHeight=height-this.utils.Const.topHeight-this.utils.Const.tabHeight-this.dataObj.listParam.paramHeight;
            if(this.dataObj.listParam.isShowBtnGroup)maxTbHeight-=this.utils.Const.listEngineBtnHeight;
            if(this.dataObj.listParam.isShowQueryParam)maxTbHeight-=this.dataObj.queryHeight;

            if(this.dataObj.refMap.get('tbRef').params.showTitle)maxTbHeight-=this.utils.Const.tbTitleHeight;
            if(this.dataObj.refMap.get('tbRef').params.showToolBar)maxTbHeight-=this.utils.Const.tbToolBarHeight;
            if(this.dataObj.refMap.get('tbRef').params.canPage)maxTbHeight-=this.utils.Const.tbPagerHeight;
            this.dataObj.refMap.get('tbRef').setMaxTbHeight(maxTbHeight);
        })
    }

    //新增和编辑的时候，弹出框
    public async showCard(options:any):Promise<void>{
        options = Object.assign({}, {ownerComp: this.proxy,dialogDiv:this.utils.UtilPub.randomString(6)}, this.dataObj.listParam,options);
        let vm = await this.utils.DialogApp.create(options);
        vm.dialogVisible = true;
    }
    //------------------------------导出excel
    public async sureExport():Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        switch (this.dataObj.exportType) {
            case 0:
                await this.exportExcel(this.dataObj.refMap.get('tbRef').getTbData());
                break;
            case 1:
                let modelPath=this.dataObj.listParam.modelMethod.substr(0,this.dataObj.listParam.modelMethod.lastIndexOf('/'));
                let res = await this.utils.Api.postRequest({url: modelPath + "/exportExcelData", params: {canPage: false}});
                await this.exportExcel(res.rows);
                break;
        }
        this.dataObj.exportExcelVisible = false;
        loading.close();
        this.proxy.$message({showClose: true, message: this.proxy.$t('listEngine.exportSuccess'), type: 'success'});
    }
    public async exportExcel(tbData:any):Promise<void>{
        return new Promise<void>((resolve, reject) => {
            const data = this.formatJson(this.dataObj.refMap.get('tbRef').tbCols.fields, tbData);//处理之后的tbData
            let fields=this.dataObj.refMap.get('tbRef').tbCols.fields;
            let labels=this.dataObj.refMap.get('tbRef').tbCols.labels;
            if (this.context.attrs['exportExcel']) {
                this.context.attrs['exportExcel'](fields, labels,data,this.proxy);
                resolve();
            } else {
                //导出数据核心就下面一行代码
                // this.proxy.excelUtils(labels, data, this.dataObj.listParam.title + this.proxy.$t('excel'));
                this.proxy.excelUtils(labels, data, this.dataObj.listParam.gridTitle + this.utils.UtilPub.getCurrentDate());
                resolve();
            }
        }).catch(err => {
            this.proxy.$message({showClose: true, message: this.proxy.$t('listEngine.exportFail'), type: 'error'});
        });
    }
    //jsonData只保留fields里面有的列
    public formatJson(fields:any, jsonData:any):void{
        return jsonData.map((rowData:any) => fields.map((field:string) => rowData[field]))
    }

    //列表固定列删除链接事件处理逻辑
    public async deleteData(row:any):Promise<void>{
        if (!await this.props.beforeDelete(row)) return;
        //注意：这里可能会有问题，/corp/pageData,下面的处理是为了得到/corp，假如后台的controller叫做/corp/abc呢？所以这里要配合着后台来用
        let modelPath=this.dataObj.listParam.modelMethod.substr(0,this.dataObj.listParam.modelMethod.lastIndexOf('/'));
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        let res = await this.utils.Api.postRequest({url: modelPath + "/delete", params: {id: row[this.dataObj.listParam.idField]}});
        loading.close();
        if(res.result){
            this.utils.Tools.success({message: res.msg});
            await this.props.afterDelete(row);//抛出删除之后接口
            //删除成功之后，重新加载表格,两种写法，第一种是回到第一页，第二种是留在当时的页码
            // queryHandler(true);
            this.dataObj.refMap.get('tbRef').queryHandler(false);
        }
    }
    //触发父组件中的某个事件，有则触发，没有就算了
    public callEvent(eventName:string,options:any):boolean{
        let _eventName='on'+this.utils.UtilPub.upFirst(eventName);
        if(this.context.attrs[_eventName]){
            this.context.emit(eventName,options);
            return true;
        }
        return false;
    }
}