import BaseBean from "@/utils/BaseBean";

export interface ICardFormDataObj {
    utilInst:CardFormUtil
    otherParams:any
}

export default class CardFormUtil extends BaseBean{
    public dataObj:ICardFormDataObj;
    public props:any;
    public context:any;

    constructor(proxy:any,dataObj:ICardFormDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public async init():Promise<any>{
        if(this.props.formItems.length%this.props.colNum==0)this.dataObj.otherParams.rowNum=this.props.formItems.length/this.props.colNum;
        else this.dataObj.otherParams.rowNum=Math.floor(this.props.formItems.length/this.props.colNum)+1;
    }
}