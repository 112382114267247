let lang={
    login:{
        //要传参的话，1-这里写成loginTitle:'用户登录{params1}'；2-调用的地方写成{{ $t('login.loginTitle',{params1:'aabbcc'}) }}
        loginTitle:'用户登录',
        usercodePlaceholder:'请输入用户名',
        passwordPlaceholder:'请输入密码',
        verifyCodePlaceholder:'请输入右侧验证码',
        sliderStartText:'拖动滑块到最右边',
        sliderSuccessText:'验证成功',
        login:'登录',
        register:'注册',
        loginSuccessMsg:'登录成功',
        loginInitWrong:'登录页面初始化出错!',
        loginNote:'请登录!',
        loginOut:'退出重新登录',
        loginOutNote:'确定注销并退出系统吗？',
        loginOutMsg:'退出登录成功!',
        completeMsg:'请填写完登录信息',
        moveSliderNote:'请拖动滑块到最右边',
        verifyCodeWrong:'验证码不正确',
        browerMsg:'为了能够正常使用系统，请使用<span style="color: red;">谷歌</span>浏览器',
        beforeContent:"'鑫源'",
        afterContent:"'集团'",
    },
    tools:{
        tip:'提示',
        warn:'警告',
        fail:'失败',
        failMsg:'操作失败',
        success:'操作成功',
        cancelOperate:'已取消该操作',
    },
    top:{
        fold_open:'折叠/打开',
        fullScreen:'全屏',
        menuList:'菜单列表',
        live2dConfig:'看板设置',
        toggleSidebar:'切换侧边栏',
        topToolbar:'显示顶部工具栏',
        menuSwitch:'菜单布局切换',
        menuSwitchTip:'是否切换菜单布局方式？',
        animationConfig:'个性化配置',
        changePersonalInfos:'修改个人信息',
        logOut:'退出登录',
        sysPub:'系统公告',
        lockScreen:'锁屏',
        accessModule:'已访问模块',
        pageAnimation:'页面动画设置',
        routerAnimation:'路由动画设置',
        themeStyle:'主题风格',
        Enable_Dialog_Background:'启用弹出框背景',
        Enable_Dialog_Animation:'是否启用弹出框动画',
        title:'说明',
        description:'勾选自动生效，无需保存。',
        closeTxt:'知道了',
        loginAnimation:'登录动画',
        mainAnimation:'主页动画',
        listAnimation:'列表动画',
        formAnimation:'表单动画',
        defaultTheme:'默认模式',
        darkTheme:'夜间模式',
        lightBlueTheme:'蓝色海洋',
    },
    tab:{
        refresh:'刷新',
        closeCurrent:'关闭当前',
        closeOthers:'关闭其他',
        closeAll:'关闭所有',
        closeLeft:'关闭左侧所有',
        closeRight:'关闭右侧所有',
        mainPage:'主界面',
        homePage:'首页',
    },
    portal:{
        layout:'布局portal',
        one_columns:'一列',
        two_columns:'两列',
        three_columns:'三列',
        add_del:'添加/删除portal',
        save:'保存',
        refresh:'刷新',
        more:'更多',
        name:'名称',
        saveLayOutTip:'你没有配置任何工作台，确定保存吗？',
    },
    listEngine:{
        loadMsg:'拼命加载中，请稍后......',
        operate:'操作',
        edit:'编辑',
        delete:'删除',
        exportConfig:'导出设置',
        exportCurrent:'导出当前页',
        exportAll:'导出所有页',
        exportSuccess:'导出成功',
        exportFail:'导出excel出错了',
        excel:'电子表格',
        queryFoldTitle:'查询条件(点击展开)',
        queryOpenTitle:'查询条件(点击折叠)',
    },
    cardEngine:{
        saveTip:'确定保存吗?',
        deleteTip:'确定删除该条记录吗？',
        addToDeleteTip:'新增状态，不能删除！',
    },
    upload:{
        upload:'上传',
        result:'上传结果',
        limitNumPre:'最多上传',
        limitNumSuf:'个文件',
        canNotDel:'不允许删除',
        delTipMsg:'确定要删除该文件吗?',
        delTip:'删除提示',
        fileNameLimit:'上传的文件名称长度不能大于90',
        fileSizeLimit:'上传文件必须小于',
        success:'上传成功',
        fail:'上传失败',
    },
    importGuid:{
        title:'导入数据',
        step1:'步骤 1',
        step2:'步骤 2',
        step3:'步骤 3',
        templateDownload:'模板下载',
        fillData:'对模板填充数据',
        uploadTemplate:'上传填好数据的模板',
        downloadPre:'是否下载',
    },
    editTable:{
        add:'新增',
        up:'上移',
        down:'下移',
        operate:'操作',
        delete:'删除',
        title:'明细数据',
    },
    combo:{
        placeholder:'请选择',
        edit:'编辑',
        refresh:'刷新',
        F_CODE:'编码',
        F_NAME:'名称',
    },
    area:{
        searchTip:'请输入关键词搜索',
        detailTitle:'行政区划明细',
        F_CODE:'行政区划代码',
        F_NAME:'名称',
    },
    layer:{
        picNotExist:'{picName}不存在',
        operateCfg:'操作设置',
        move:'移动',
        merge:'合并',
        folderName_placeholder:'请输入合并后的文件夹名称',
        icon:'图标',
        animation:'动画',
    },
    selectDate: "Select date111",
    normal:'正常',
    stop:'停用',
    lock:'锁定',
    yes:'是',
    no:'否',
    man:'男',
    woman:'女',
    close:'关闭',
    closeForever:'永久关闭',
    confirm:'确定',
    cancel:'取消',
    loadMsg:'正在执行，请稍后......',
    mainLoadMsg:'请稍后，正在准备数据......',
    networkWrong:'很遗憾网络有点小问题',
    deleteNote:'此操作将删除该条记录, 是否继续?',
    _404Msg:'您当前访问的页面出错，请联系超级管理员或退出账号重新登录',
    unit:'元',
    downloadFileFail:'文件下载失败',
    fullScreenMsg:'你的浏览器不支持该功能',
}

export let addProperty=(objs:any)=>{
    Object.assign(lang,objs);
}
export default lang