import UtilPub from "@/utils/UtilPub";
import Const from "@/utils/Const";
import Tools from "@/utils/Tools";
import BillComponent from "@/utils/BillComponent";
import DialogApp from "@/utils/DialogApp";
import Axios from "@/utils/axios";
import Websocket from "@/utils/Websocket";
import AliOss from "@/utils/aliyun.oss";
import map from "@/utils/map";
import AnimateUtil from "@/utils/extract/animateUtil";
import Event from "@/views/sysviews/config/business/event"

import Api from "@/utils/api/Api"
import OrderProviderApi from "@/utils/api/OrderProviderApi"

export default {
    UtilPub,Const,Tools,BillComponent,DialogApp,Axios,Websocket,AliOss,map,
    AnimateUtil,Event,
    Api,OrderProviderApi
}

