import BaseBean from "@/utils/BaseBean";
import {inject} from "vue";

export interface IEditTableDataObj {
    utilInst:EditTableUtil
    editParams:any
    fields:Array<any>
    insert:Array<any>
    update:Array<any>
    delete:Array<any>
    detailTypeRef:any
    detailType:any
}
export default class EditTableUtil extends BaseBean{
    /**
     * 使用EditTable的地方必须provide一个EditTableOwner（现在可以不用了）
     * 目的为了可以使用引用页的方法，否则要在这里用很多this.$parent.$parent.....
     * */
    editTableOwner = inject('EditTableOwner') as any;
    public dataObj:IEditTableDataObj;
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IEditTableDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //数据表格加载完毕事件
    public async gridLoaded(res:any):Promise<void>{
        this.resetArray();
        this.props.gridLoaded(this.dataObj.detailType,res);
    }
    //处理增加行事件
    public async addDetail():Promise<any>{
        if(this.callEvent('addDetail', {}))return;
        //根据表格列构造一个空行对象，方便拿来给新增行用，默认新增行可以编辑
        let row = {EDIT:true} as any;
        this.dataObj.fields.forEach((field:string)=> {
            row[field] = "";//给新增行row赋上表格的属性，这里的this就是row对象
        }, row);//传入row就是为了改变this
        //看父组建是否定义了新增之前的处理事件
        if (!await this.props.beforeAdd({row:row,parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy})) return false;
        if (!await this.props.beganEdit({parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy})) return false;
        //添加之前，把其它行状态全部置为不可编辑状态
        if(await this.setAllRowCanNotEdit())this.dataObj.detailTypeRef.tbData.push(row);  //把新增的空行添加进表格显示
    }
    //处理删除行事件
    public async delDetail(row:any, index:number):Promise<void>{
        if(this.callEvent('delDetail', {row, index}))return;
        let idField = this.dataObj.editParams.idField;
        //执行删除之前的事件
        if (!await this.props.beforeDel({row:row, index:index,parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy})) return;
        //删除表格指定的行
        this.dataObj.detailTypeRef.tbData.splice(index, 1);
        //如果该条记录有id，出了前台删除它，后台也需要删除，把需要后台删除的记录放到delete数组中
        //如果该行是新增的，那么id肯定为空，所以不为空肯定是从后台加载出来的，那么要删除后台对应记录
        if (row[idField]) this.dataObj.delete.push(row[idField]);
        //如果这条要被删除的记录在insert和upate数组中也存在，那么需要把他们移除，否则删除不了
        this.deleteFromInsert(row);
        this.deleteFromUpdate(row);
        //删除后事件
        await this.props.afterDel({row, index,parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy})
    }
    //点击行事件，把其它行变为不可编辑，把当前点击行变为可编辑状态
    public async clickTableRowHandler(options:any):Promise<void>{
        if(this.context.attrs['onRowClick'])return;
        let flag=true;
        if (this.dataObj.editParams.readOnly) return; //如果表格只读，直接返回
        if (options.row.EDIT) return; //如果已经处于编辑状态，则不管了，直接返回
        options.parentInst=this.props.parentInst
        //如果定义了beganEdit则调用beganEdit
        if (!await this.props.beganEdit(options)) return;
        //如果beganEdit返回true，让变表格其它所有行为不可编辑状态
        flag=await this.dataObj.utilInst.setAllRowCanNotEdit();
        //让当前点击行处于编辑状态,可是很遗憾，必须clickTableRow执行完了肉眼才能看到被点击行的状态为编辑了
        if(flag)this.dataObj.detailTypeRef.tbData[options.row.rowIndex].EDIT = !options.row.EDIT;
        if (flag && !this.dataObj.editParams.readOnly) await this.props.afterBeganEdit(options);
    }
    //把表格所有行处于不可编辑状态
    public async setAllRowCanNotEdit():Promise<boolean>{
        let idField = this.dataObj.editParams.idField;
        let tbData = this.dataObj.detailTypeRef.tbData;
        for (let i = 0; i < tbData.length; i++) {
            if (tbData[i].EDIT) {
                //如果某行处于编辑状态，则结束其编辑状态
                if (!await this.props.beforeEndEdit({row:tbData[i], index:i,detailType:this.dataObj.detailType,parentInst:this.props.parentInst,proxy:this.proxy})) return false;
                tbData[i].EDIT = false;
                //在结束编辑时，看是否有ID，有则放入更新数组，没有则放入新增数组。
                if (tbData[i][idField]) {
                    this.dataObj.update.push(tbData[i]);
                } else {
                    this.dataObj.insert.push(tbData[i]);
                }
            }
        }
        this.dataObj.detailTypeRef.tbData = tbData;
        return true;
    }
    //清除表格数据
    public async clearDetailData():Promise<void>{
        let tbData=this.dataObj.detailTypeRef.tbData;
        let params={tbData:tbData,parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy};
        if (!await this.props.beforeClearData(params)) return;
        while (tbData.length!=0){
            // let row=tbData.shift();
            let row=tbData[0];
            await this.delDetail(row,0);//触发删除行事件，因为父类可能需要在删除行的时候做一些操作
        }
        this.dataObj.update.length=0;//把更新、插入数组清空
        this.dataObj.insert.length=0;
        // this.dataObj.detailTypeRef.clearTbData();//delDetail里面已经一行行的删除了
        let _params={deleteArr:this.dataObj.delete,parentInst:this.props.parentInst,detailType:this.dataObj.detailType,proxy:this.proxy};
        await this.props.afterClearData(_params);
    }
    //处理上移事件
    public async up():Promise<void>{
        if(this.callEvent('up', {}))return;
        this.doUpDown('up');
    }
    //处理下移事件
    public async down(context:any):Promise<void>{
        if(this.callEvent('down', {}))return;
        this.doUpDown('down');
    }
    //交换行，上移或下移
    public doUpDown(type:string):void{
        let grid=this.dataObj.detailTypeRef;
        let selection=grid.getSelection() as any;
        if(selection.length==1){
            let rowIndex=selection[0].rowIndex;
            if('up'==type){
                if(rowIndex!=0){
                    let data=this.dataObj.detailTypeRef.tbData;
                    [data[rowIndex-1],data[rowIndex]]=[data[rowIndex],data[rowIndex-1]]
                }
            }else{
                let data=this.dataObj.detailTypeRef.tbData;
                if(rowIndex!=data.length-1)[data[rowIndex+1],data[rowIndex]]=[data[rowIndex],data[rowIndex+1]]
            }
        }
    }
    //删除insert数组中指定的项
    public deleteFromInsert(row:any):void{
        this.dataObj.insert=[...new Set(this.dataObj.insert)]
        this.utils.UtilPub.delItemFromArr(this.dataObj.insert,row);
    }
    //删除update数组中指定的项
    public deleteFromUpdate(row:any):void{
        this.dataObj.update=[...new Set(this.dataObj.update)]
        this.utils.UtilPub.delItemFromArr(this.dataObj.update,row);
    }
    //重置插入、更新、删除数组
    public resetArray():void{
        this.dataObj.insert=[];this.dataObj.update=[];this.dataObj.delete=[];
    }
    //触发父组件中的某个事件，有则触发，没有就算了
    public callEvent(eventName:string,options:any):boolean{
        let _eventName='on'+this.utils.UtilPub.upFirst(eventName);
        if(this.context.attrs[_eventName]){
            if(!options.parentInst)options.parentInst=this.props.parentInst;//这句话在由引擎充当页面的时候非常有用
            this.context.emit(eventName,options);
            return true;
        }
        return false;
    }
}