import {reactive, toRefs, onBeforeMount,onMounted,defineComponent,getCurrentInstance, watch} from 'vue';
import UnitUtil ,{IUnitDataObj} from "./unitUtil";
const updateKey='update:modelValue';
export default defineComponent ({
    name: "split",
    props: {
        //props的这些属性是通过组件的属性传进来的，比如<Unit v-model="lbParams.testValue" unit='个'/>
        //就会把unit传进来覆盖掉props中的unit。值得注意的是，上面的v-model="lbParams.testValue"将会覆盖modelValue的值。
        modelValue: {//必须和上面updateKey中的modelValue叫一样的名称
            type: [Number,String],
            default: 0 || ''
        },
        type: {type: String,default: 'numberInput'},
        //这些是el-input-number的属性
        min: {type: Number,default: 0},
        max: {type: Number,default: 9999999},
        unit:{type:String,default: '元'}
    },
    setup(props,context){
        //context.attrs将会收到组件v-bind:传进来的参数
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUnitDataObj=reactive<IUnitDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{
                unit:props.unit,
                numberAttrs:{
                    defaultValue:props.modelValue,
                    type:props.type,
                    min:props.min,
                    max:props.max
                },
                inputAttrs:{
                    defaultValue:''
                }
            }
        })

        onBeforeMount(()=>{
            dataObj.utilInst=new UnitUtil(proxy,dataObj);
        })
        onMounted(()=>{
            // dataObj.otherParams.unit=proxy.$t('unit');//不能这样，否则外界传入的单位不会生效
        })
        //当遇到卡片这种情况，最开始的时候，form里面的属性都是空或者0，请求完后台的add或者edit之后，才会为form赋值，
        //这个时候unit组件绑定form才会收到请求之后的值，但是很遗憾，在请求完add或者edit之后，给form赋值，这个时候，unit已经初始化了，
        //defaultValue也初始化了，不会再次调用，只是会改变props.modelValue的值，所以我通过监听props.modelValue的变化来为defaultValue赋值，
        //这样就显示正确了，否则只会显示没有请求add/edit之前form的默认值
        watch(() => props.modelValue,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.defaultValue=newValue;
            dataObj.otherParams.inputAttrs.defaultValue=newValue;
        })
        //el-input-number值改变的时候，向父组件抛事件，且要更新modelValue的值，这样在父组件那里才能v-model
        const numberInputChange=(currentValue: number, oldValue: number)=>{
            context.emit(updateKey,currentValue);
            context.emit('changeHandler',currentValue,oldValue);
        }
        const inputChange=(currentValue: string)=>{
            context.emit(updateKey,currentValue);
            context.emit('changeHandler',currentValue);
        }
        watch(() => props.unit,async (newValue,oldValue) => {
            dataObj.otherParams.unit=newValue;
        })
        watch(() => props.type,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.type=newValue;
        })
        watch(() => props.min,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.min=newValue;
        })
        watch(() => props.max,async (newValue,oldValue) => {
            dataObj.otherParams.numberAttrs.max=newValue;
        })
        return{
            ...toRefs(dataObj),numberInputChange,inputChange
        }
    },
    components: {}
});