import BaseBean from "@/utils/BaseBean";
interface ITbCol {
    labels:Array<string>
    fields:Array<string>
}
export interface IGridTableDataObj{
    utilInst:any
    refMap:Map<string,any>
    selRowId:any
    tbTitle:string
    loading: boolean//是否显示正在加载
    hasSelection:boolean //是否有复选框，即是否有checkbox列
    tbData: Array<any>//表格的数据
    tbCols: ITbCol//表格的列
    currentPage: number//当前页
    total: number//总条数，不是总页数
    maxTbHeight:number//默认表格的最大高度
    params:any
    otherParams:any
}
export default class GridTableUtil extends BaseBean{
    public dataObj:IGridTableDataObj;
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IGridTableDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //设置表格列信息
    public setTbColumnInfoBySlot():void{
        const labels:Array<string> = [];
        const fields:Array<string> = [];
        let tbCols=this.context.slots.tbCols();//得到的内容分为两类：_tbCols和_fixCol，其中_tbCols放的是普通列；_fixCol存放的是设置过fixed属性的列

        for (let i = 0; i < tbCols.length; i++) {
            if('_tbCols'==tbCols[i].key){
                let gridColumns=tbCols[i].children;
                for (let j = 0; j < gridColumns.length; j++) {
                    if(typeof(gridColumns[j].type)!='object')continue;//如果是el-table-column，类型是object；如果是备注，类型是symbol；这里只处理el-table-column
                    if(gridColumns[j].props.type)continue;
                    let label=gridColumns[j].props.label;
                    let prop=gridColumns[j].props.prop;
                    //多选框列是checkbox，这个时候label和prop都为undefined
                    if(label)labels.push(label);
                    if(prop)fields.push(prop);
                    if(!prop){
                        let cols=[];
                        if(gridColumns[j].children)cols=gridColumns[j].children.default();
                        for(let k=0;k<cols.length;k++){
                            //多选框列是checkbox，这个时候label和prop都为undefined
                            if(cols[k].props && cols[k].props.label)labels.push(cols[k].props.label);
                            if(cols[k].props && cols[k].props.prop)fields.push(cols[k].props.prop);
                        }
                    }
                }
            }else{//所有设置了fixed的列放到了_fixCol里面，
                if(tbCols[i].children && !this.dataObj.params.multiple){
                    for (let j = 0; j < tbCols[i].children.length; j++) {
                        if(typeof(tbCols[i].children[j].type)!='object')continue;
                        const props=tbCols[i].children[j].props;
                        if(props && props.type && props.type=='selection'){
                            this.dataObj.params.multiple=true; //是否有复选框，即是否有checkbox列
                            break;
                        }
                    }
                }
            }
        }
        this.dataObj.tbCols.labels = labels;
        this.dataObj.tbCols.fields = fields;
    }
    //如果表格列是从后台设置,则该方法将被调用
    public setTbColumnInfoByColumnList():void{
        const labels:Array<string> = [];
        const fields:Array<string> = [];
        let columnList=this.dataObj.params.columnList;
        columnList.forEach((item:any,index:number)=>{
            labels[index]=item.label;
            fields[index]=item.prop;
        })
        this.dataObj.tbCols.labels = labels;
        this.dataObj.tbCols.fields = fields;
        // console.log(this.dataObj.tbCols)
    }
}