import config from "@/utils/config";
import utils from '@/utils/Jh.core';
export default class AnimateUtil{
    //如果启用了列表动画，则需要为操作按钮、查询条件加上animate
    public static listBtnConditionAnimate():void{
        if(utils.Tools.isAnimationOpen('listAnimation') && config.flag1){
            //头部操作按钮动画
            let buttons:any=document.querySelectorAll('.btnGroup .el-button');
            for(let i=0;i<buttons.length;i++){
                buttons[i].classList.add('animate__animated', 'animate__bounceIn');
                buttons[i].style.setProperty('animation-delay', (0.4*i+0.5)+'s');
            }
            //查询条件动画
            let myRows:any=document.querySelectorAll('.myRow');
            if(myRows){
                for(let i=0;i<myRows.length;i++){
                    myRows[i].classList.add('animate__animated', 'animate__slideInRight');
                    myRows[i].style.setProperty('animation-delay', (0.4*i+0.5)+'s');
                }
            }
        }
    }
    //表格动画，用于列表表格，所以前提是开启了列表动画
    public static gridAnimate(utils:any):void{
        if(utils.Tools.isAnimationOpen('listAnimation') && config.flag1){
            let tbody:any=document.querySelector('tbody');
            //animate__animated是必须加入的基本class，后面再随机取一个class
            tbody.classList.add('animate__animated', utils.UtilPub.randomAnimateStr());
        }
    }
    //表格行动画（非常影响效率，所以一般不启用）
    public static gridRowAnimate():void{
        let aa=document.querySelectorAll('tr');
        if(aa){
            for(let i=0;i<aa.length;i++){
                aa[i].classList.add('animate__animated', 'animate__bounceInRight');
                aa[i].style.setProperty('animation-delay', 0.2*i+'s');
            }
        }
    }

    //配置弹出框打开关闭动画中需要的css变量
    public static dialogOpenCloseAnimationParams():void{
        if(utils.Tools.isAnimationOpen('dialogAnimation')){
            let randDeg=Math.random()*180;
            document.documentElement.style.setProperty('--dialog-rotate-deg-in', randDeg+'deg');
            document.documentElement.style.setProperty('--dialog-rotate-deg-out', -randDeg+'deg');
            document.documentElement.style.setProperty('--dialog-animation-out', 'dialog-fade-out 1.8s');

            document.documentElement.style.setProperty('--dialog-translate3d_x-in', '50%');
            document.documentElement.style.setProperty('--dialog-translate3d_y-out', '-100%');
            document.documentElement.style.setProperty('--dialog-translate3d_z-out', '-800px');
            document.documentElement.style.setProperty('--dialog-scale-out', '0.1');
        }else{
            document.documentElement.style.setProperty('--dialog-rotate-deg-in', 0+'deg');
            document.documentElement.style.setProperty('--dialog-rotate-deg-out', -0+'deg');
            document.documentElement.style.setProperty('--dialog-animation-out', 'none');

            document.documentElement.style.setProperty('--dialog-translate3d_x-in', '0');
            document.documentElement.style.setProperty('--dialog-translate3d_y-out', '0');
            document.documentElement.style.setProperty('--dialog-translate3d_z-out', '0');
            document.documentElement.style.setProperty('--dialog-scale-out', '1');
        }
    }
    //弹出框表单动画
    public static dialogFormAnimate():any{
        //如果顶部设置过不启用，则只返回空（没有设置代表启用）
        if(!utils.Tools.isAnimationOpen('dialogAnimation') || !config.flag1){
            return {}
        }

        let arr=new Array(18);arr.fill(false);
        let randIndex=Math.floor(Math.random() * arr.length);
        arr[randIndex]=true;
        let index=0;
        return {
            animate__animated: true,//要用animate必须要写animate__animated
            animate__swing:arr[index++],
            animate__bounceInDown:arr[index++],
            animate__bounceInRight:arr[index++],
            animate__bounceInUp:arr[index++],
            animate__fadeInDown:arr[index++],
            animate__fadeInRight:arr[index++],
            animate__flipInX:arr[index++],
            animate__flipInY:arr[index++],
            animate__lightSpeedInRight:arr[index++],
            animate__rotateIn:arr[index++],
            animate__rotateInDownLeft:arr[index++],
            animate__rotateInDownRight:arr[index++],
            animate__rotateInUpRight:arr[index++],
            animate__rollIn:arr[index++],
            animate__slideInDown:arr[index++],
            animate__slideInUp:arr[index++],
            animate__slideInLeft:arr[index++],
            animate__slideInRight:arr[index++]
        };
    }
    //弹出框表单项动画
    public static dialogFormItemAnimate(utils:any):any{
        //如果弹出框也有动画，需要弹出框动画完成之后再执行弹出框中的表单、操作按钮动画，所以这里计算一下延时时间
        let delay=0;
        if(utils.Tools.isAnimationOpen('dialogAnimation')  && config.flag1)delay=0.4;

        //如果启用了表单动画，则需要为表单操作按钮、表单项加上animate
        if(utils.Tools.isAnimationOpen('formAnimation') && config.flag1){
            //头部操作按钮动画
            let buttons:any=document.querySelectorAll('.btnGroup .el-button');
            for(let i=0;i<buttons.length;i++){
                buttons[i].classList.add('animate__animated', 'animate__bounceIn');
                buttons[i].style.setProperty('animation-delay', (0.2*i+delay)+'s');
            }

            let formItems:any=document.querySelectorAll('.backTarget .el-form-item');
            let randFadeInType=utils.UtilPub.randomFadeinAnimateStr();
            for(let i=0;i<formItems.length;i++){
                formItems[i].classList.add('animate__animated', randFadeInType);
                formItems[i].style.setProperty('animation-delay', (0.1*i+delay)+'s');
            }
        }
    }
    //弹出框背景动画（通过伪元素的方式设置背景图,注意，注意，注意：如果启用弹出框的背景图片效果，必须把app.css中关于弹出框背景图片设置的css注释打开，否则不会生效）
    public static dialogBgAnimate(showFooterBtn:boolean,utils:any):void{
        //我们下面手动插入的伪元素是插入到styleSheets[0]中的，所以这里取出styleSheets[0]的长度
        let pos=document.styleSheets[0].rules.length;//console.log(document.styleSheets)
        let dom:any=document.styleSheets[0].rules[pos-1];//最后一个伪元素名称
        let beforeName=dom.selectorText;//最后一个伪元素名称
        //如果最后一个伪元素名称是.el-dialog::before，说明我们已经插入过该伪元素了，所以需要删除掉它，
        //否则下面又插入，就会造成不停打开弹出框，就不停插入伪元素。
        if('.el-dialog::before'==beforeName){
            pos=pos-1;
            document.styleSheets[0].deleteRule(pos);
        }
        if(utils.Tools.isAnimationOpen('dialogBgAnimation')){//启用了背景图或者根本就没有设置过，那么就默认启用，除非手动关闭弹出框背景图片设置，那么就不去设置背景图片了
            //以下addRule是支持IE的写法
            if(showFooterBtn){//如果要展示弹出框的脚部，则不设置背景图了
                // document.styleSheets[0].addRule('.el-dialog::before',"width:0px;height:0px;background-image:none");
                document.styleSheets[0].insertRule('.el-dialog::before {width:0px;height:0px;background-image:none}',pos);
            }else{
                const backPicPath=utils.UtilPub.decrypt(localStorage.getItem('backPicPath') as string);
                if(!backPicPath || backPicPath=='_dynamic_'){//如果没有设置过用哪张图片作为背景，那么就默认随机。或者手动设置随机图片
                    //动态获取图片，比较消耗资源
                    let _imageSource:string=sessionStorage.getItem("imageSource") as string;
                    let imageSource:Array<ResourceFileBean>=JSON.parse(utils.UtilPub.decrypt(_imageSource)) as Array<ResourceFileBean>;
                    if(imageSource.length!=0){
                        let picIndex=Math.floor(Math.random()*imageSource.length);//随机生成背景图片下标
                        let url='url('+imageSource[picIndex].path+')';
                        // document.styleSheets[0].addRule('.el-dialog::before',"opacity: 0.3;width: 100%;height: 100%;background-image:"+url+";background-size: cover;");
                        document.styleSheets[0].insertRule('.el-dialog::before {z-index:-1;opacity: 0.3;width: 100%;height: 100%;background-image:'+url+';background-size: cover;}',pos);
                    }else{//有可能用户一张图都没有，那么就给一张默认图吧
                        import('@/assets/picResources/3.jpg').then((img) => {
                            document.styleSheets[0].insertRule('.el-dialog::before {z-index:-1;opacity: 0.3;width: 100%;height: 100%;background-image: url('+img.default+');background-size: cover;}',pos);
                        });
                    }
                }else{//手动指定过某张固定的图片来作为dialog的背景图
                    let url='url('+backPicPath+')';
                    // document.styleSheets[0].addRule('.el-dialog::before',"opacity: 0.3;width: 100%;height: 100%;background-image:"+url+";background-size: cover;");
                    document.styleSheets[0].insertRule('.el-dialog::before {z-index:-1;opacity: 0.3;width: 100%;height: 100%;background-image:'+url+';background-size: cover;}',pos);
                }
                //设置静态资源，不会消耗多大的资源，但是图片被固定死了。
                // document.styleSheets[0].addRule('.el-dialog::before',"opacity: 0.3;width: 100%;height: 100%;background-image: url("+require('@/assets/picResources/3.jpg')+");background-size: cover;");
            }
        }else{//如果手动设置了不显示弹出框背景图片，那么需要手动清除掉背景图片，因为一旦显示过，就tmd像有缓存一样，奇怪，所以这里手动清除一下吧
            // document.styleSheets[0].addRule('.el-dialog::before',"width:0px;height:0px;background-image:none");
            document.styleSheets[0].insertRule('.el-dialog::before {width:0px;height:0px;background-image:none}',pos);
        }
    }
}