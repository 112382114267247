import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue'
export interface IDialogDataObj {
    utilInst:DialogUtil
    refMap:Map<string,any>
    customClass:string//dialog的自定义class，方便关闭的时候从body上移除
    modelType:any//当前dialog包含的组件类型
    engineRef:any//当前dialog包含的组件的ref，系统自动赋值
    engine:any//当前dialog包含的组件
    engineParams:any//外界调用dialog的参数，此参数将传给引擎
    fullscreenLoading: boolean//是否显示正在加载loading对话框
    options: any
    dialogVisible: boolean//dialog是否影藏
    otherParams:any
}
export default class DialogUtil extends BaseBean{
    public dataObj:IDialogDataObj;
    constructor(proxy:any,dataObj:IDialogDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //初始化dialog的参数
    public initDialogParams(engineParams:any):void{
        nextTick(()=>{
            //弹出框包含组件定义的属性（比如包含的卡片），engineParams
            let modelCompProp:any={};
            //如果传入了业务模块，而且业务模块指定了title，则采用业务模块的title属性作为dialog的标题
            if(engineParams.modelComp && engineParams.modelComp.title!= undefined)modelCompProp.title=engineParams.modelComp.title;
            //如果传入了业务模块，而且业务模块指定了fullscreen，则采用业务模块的fullscreen属性来决定是否全屏dialog
            if(engineParams.modelComp && engineParams.modelComp.fullscreen!= undefined)modelCompProp.fullscreen=engineParams.modelComp.fullscreen;
            //如果传入了业务模块，而且业务模块指定了dWidth，则采用业务模块的dWidth属性来决定dialog的宽度
            if(engineParams.modelComp && engineParams.modelComp.dWidth!= undefined)modelCompProp.dWidth=engineParams.modelComp.dWidth;
            //如果传入了业务模块，而且业务模块指定了showMaxRecover，则采用业务模块的showMaxRecover属性来决定是否显示最大化和恢复按钮
            if(engineParams.modelComp && engineParams.modelComp.showMaxRecover!= undefined)modelCompProp.showMaxRecover=engineParams.modelComp.showMaxRecover;
            Object.assign(this.dataObj.options,engineParams,modelCompProp);//合并处理后的参数
            // if(fixProp.fullscreen)this.dataObj.options.contentHeight='';//如果全屏显示的话，则contentHeight必须设置为''，否则会有高度
        })
    }
    //构建dialog包含的组件
    public buildDialogContain(engineParams:any):void{
         //dialog包含的组件类别说明
         //如果传入dialog的参数engineParams里面指定了modelType，则根据传入的modelType来决定dialog采用哪种引擎组件来包含业务组件；
         //如果传入dialog的参数engineParams里面没有指定modelType，那么modelType的值就等于dialog要包含的业务组件modelComp下的modelType属性。
         //dialog一定会传入要包含的业务组件modelComp，除非dialog包含的是链接，那么就不用传入modelComp属性。
         //而modelComp一定会包含modelType属性，除非modelComp是列表。
         //所以如果没有传入modelComp且没有指定modelType，那么dialog就肯定是包含的列表

         //目前dialog支持包含卡片、单据、链接、列表。对于卡片和单据，在打开dialog的时候，不会直接给dialog指定modelType参数，
         //而是传给dialog参数--->modelComp，modelComp是具体的业务组件，而在业务组件里面指定了modelType
         //如果dialog要打开一个链接，那么就必须指定modelType，而且值固定为link
         //如果dialog要打开一个列表或者其它组件，则不用指定modelType，系统默认会给620px的高度。
        let modelType=engineParams.modelType?engineParams.modelType:engineParams.modelComp.modelType;
        this.dataObj.modelType=modelType;
        if(!modelType || modelType=='list'){//弹出的是列表或者其它组件
            this.dataObj.engine=engineParams.modelComp;//不要搞晕了,这里的modelComp不是ListEngine哦,而是具体的列表页.fuck
            //如果不是全屏且弹出框里面包含的是列表，那么需要按弹出框的内容高度来计算表格的最大高度，列表里面默认是按window.innerHeight来计算的表格最大高度
            //注意：在往弹出框传递参数的时候，最好显示传递modelType:list，因为下方有判断
            //注意：1、必须加上nextTick，因为在amount方法里面，调用buildDialogContain方法之前，调用了initDialogParams方法，而initDialogParams加了nextTick，
            //        如果这里不加nextTick，得到的参数有可能不准确，因为在initDialogParams方法里面有改变，所以这里也必须加上nextTick
            //     2、不能把下方的方法放到opened里面，因为列表引擎里面在onMounted里面调用了表格计算高度的方法，而列表引擎的onMounted方法会在opened方法之前执行，
            //        但是会在这里之后执行，所以为了在列表引擎计算高度之前改变他的计算因子，应该写在这里，而不是opened方法里面
            nextTick(()=>{
                if(!this.dataObj.options.fullscreen) {
                    this.cfgListEngineContentHeight(false);
                }
            })
        }else{
            if('card'===modelType){//如果想要弹出的是卡片，则用卡片引擎来包裹住要弹出的内容
                if('true'==sessionStorage.getItem('needMultiLanguage'))engineParams.modelComp.title=this.proxy.$t(engineParams.modelComp.name);//哎，这这里国际化卡片的标题吧
                import("@/components/base_comp/cardEngine/CardEngine.vue").then(component=>this.dataObj.engine=component.default);
            }if('bill'===modelType){//如果想要弹出的是单据，则用单据引擎来包裹住要弹出的内容
                if('true'==sessionStorage.getItem('needMultiLanguage'))engineParams.modelComp.title=this.proxy.$t(engineParams.modelComp.name);//哎，这这里国际化卡片的标题吧
                import("@/components/base_comp/billEngine/BillEngine.vue").then(component=>this.dataObj.engine=component.default);
            }else if('link'===modelType){//如果想要弹出的是链接，则用LinkComponent组件来包裹住要弹出的内容
                import("@/components/base_comp/linkComponent/LinkComponent.vue").then(component=>this.dataObj.engine=component.default);
            }
        }
     }

    //当弹出框内容是列表引擎的时候，需要设置列表引擎高度计算因子，否则会按照window.innerHeight来作为计算因子
    public cfgListEngineContentHeight(reCalFlag:boolean):void{
        if(this.dataObj.modelType && this.dataObj.modelType=='list' && this.dataObj.options.contentHeight){
            let contentHeight=this.dataObj.options.contentHeight;
            if(contentHeight.indexOf('px')>0)contentHeight=contentHeight.substr(0,contentHeight.indexOf('px'));
            this.dataObj.engineRef.pageListRef.utilInst.setSpeHeight((parseInt(contentHeight)+this.utils.Const.topHeight+this.utils.Const.tabHeight),reCalFlag);
        }
    }
}