import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch} from 'vue';
import CardFormUtil ,{ICardFormDataObj} from "./cardFormUtil";
import CardFormItem from './cardFormItem/CardFormItem.vue'
export default defineComponent ({
    name: "card",
    props:{
        ownerInst:{type: Object, default:()=>{return{}}},//引用该组件的组件（这里是业务卡片）
        colNum:{type: Number, default:1},//每行显示几列
        disabled:{type: Boolean, default:false},//是否禁用组件
        formRef:{type: Object, default:()=>{return{}}},//传给formItem用，在comboGrid、comboTree中用到
        form:{type: Object, default:()=>{return{}}},//外界的form表单，这里改变了，外界也跟着改变，不是说不能改变props吗?
        refMap:{type: Object, default:()=>{return new Map()}},//外界的refMap表单，这里改变了，外界也跟着改变，不是说不能改变props吗?
        formItems:{type: Array, default:()=>{return[]}}//表单项
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ICardFormDataObj=reactive<ICardFormDataObj>({
            utilInst:{} as any,
            otherParams:{
                formRules:[],//表单验证规则(暂时没有，可删除)
                rowNum:props.formItems.length
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CardFormUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await dataObj.utilInst.init();
        })
        watch(() => props.formItems,async (newValue,oldValue) => {
            await dataObj.utilInst.init();
        })
        return{
            ...toRefs(dataObj)
        }
    },
    components:{CardFormItem}
});