import BaseBean from "@/utils/BaseBean";

export interface ISplitDataObj {
    utilInst:SplitUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class SplitUtil extends BaseBean{
    public dataObj:ISplitDataObj

    constructor(proxy:any,dataObj:ISplitDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //pc
    public drag():void{
        if(!this.dataObj.refMap.get('resize'))return;
        // 鼠标按下事件
        this.dataObj.refMap.get('resize').onmousedown =  (e:any) =>{
            //颜色改变提醒
            // this.dataObj.refMap.get('resize').style.background = '#818181';
            let startX = e.clientX;
            this.dataObj.refMap.get('resize').left = this.dataObj.refMap.get('resize').offsetLeft;
            // 鼠标拖动事件
            document.onmousemove =  (e)=> {
                let endX = e.clientX;
                let moveLen = this.dataObj.refMap.get('resize').left + (endX - startX); // （endx-startx）=移动的距离。this.dataObj.refMap.get('resize').left+移动的距离=左边区域最后的宽度
                let maxT = this.dataObj.refMap.get('box').clientWidth - this.dataObj.refMap.get('resize').offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

                if (moveLen < this.dataObj.otherParams.lMinWidth) moveLen = this.dataObj.otherParams.lMinWidth; // 左边区域的最小宽度为lMinWidth
                if (moveLen > maxT - this.dataObj.otherParams.rMinWidth) moveLen = maxT - this.dataObj.otherParams.rMinWidth; //右边区域最小宽度为rMinWidth

                this.dataObj.refMap.get('resize').style.left = moveLen; // 设置左侧区域的宽度
                // this.dataObj.refMap.get('resize').style.left = endX; // 设置左侧区域的宽度
                this.dataObj.refMap.get('left').style.width = moveLen + 'px';
                this.dataObj.refMap.get('right').style.width = (this.dataObj.refMap.get('box').clientWidth - moveLen - 10) + 'px';
            };
            // 鼠标松开事件
            document.onmouseup =  (evt)=> {
                //颜色恢复
                // this.dataObj.refMap.get('resize').style.background = '#d6d6d6';
                document.onmousemove = null;
                document.onmouseup = null;
                this.dataObj.refMap.get('resize').releaseCapture && this.dataObj.refMap.get('resize').releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
                //之前没有加下面这行，但是在主页改变左边宽度之后，在点击菜单或者点击tabs中已打开菜单的时候，会发现左边宽度又还原了，于是才加上下方的代码。
                //下方代码目的是重新计算lwidth的宽度并设值，至于为什么*100还要加1，不知道为什么，如果不加1，移动之后老是少一点宽度，体验不好，估计是Math.floor向下取整的原因
                this.dataObj.otherParams.lwidth=Math.floor((this.dataObj.refMap.get('left').clientWidth/this.dataObj.refMap.get('box').clientWidth)*100+1)+"%";
            };
            this.dataObj.refMap.get('resize').setCapture && this.dataObj.refMap.get('resize').setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
            return false;
        };
    }
    //手机
    public phoneDrag():void{
        if(!this.dataObj.refMap.get('resize'))return;
        // 鼠标按下事件
        this.dataObj.refMap.get('resize').ontouchstart  =  (e:any) =>{
            //颜色改变提醒
            // this.dataObj.refMap.get('resize').style.background = '#818181';
            let startX = e.touches[0].clientX;
            this.dataObj.refMap.get('resize').left = this.dataObj.refMap.get('resize').offsetLeft;
            // 鼠标拖动事件
            document.ontouchmove =  (e)=> {
                let endX = e.touches[0].clientX;
                let moveLen = this.dataObj.refMap.get('resize').left + (endX - startX); // （endx-startx）=移动的距离。this.dataObj.refMap.get('resize').left+移动的距离=左边区域最后的宽度
                let maxT = this.dataObj.refMap.get('box').clientWidth - this.dataObj.refMap.get('resize').offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

                if (moveLen < this.dataObj.otherParams.lMinWidth) moveLen = this.dataObj.otherParams.lMinWidth; // 左边区域的最小宽度为lMinWidth
                if (moveLen > maxT - this.dataObj.otherParams.rMinWidth) moveLen = maxT - this.dataObj.otherParams.rMinWidth; //右边区域最小宽度为rMinWidth

                this.dataObj.refMap.get('resize').style.left = moveLen; // 设置左侧区域的宽度
                // this.dataObj.refMap.get('resize').style.left = endX; // 设置左侧区域的宽度
                this.dataObj.refMap.get('left').style.width = moveLen + 'px';
                this.dataObj.refMap.get('right').style.width = (this.dataObj.refMap.get('box').clientWidth - moveLen - 10) + 'px';
            };
            // 鼠标松开事件
            document.ontouchend =  (evt)=> {
                //颜色恢复
                // this.dataObj.refMap.get('resize').style.background = '#d6d6d6';
                document.ontouchmove = null;
                document.ontouchend = null;
                this.dataObj.refMap.get('resize').releaseCapture && this.dataObj.refMap.get('resize').releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
                //之前没有加下面这行，但是在主页改变左边宽度之后，在点击菜单或者点击tabs中已打开菜单的时候，会发现左边宽度又还原了，于是才加上下方的代码。
                //下方代码目的是重新计算lwidth的宽度并设值，至于为什么*100还要加1，不知道为什么，如果不加1，移动之后老是少一点宽度，体验不好，估计是Math.floor向下取整的原因
                this.dataObj.otherParams.lwidth=Math.floor((this.dataObj.refMap.get('left').clientWidth/this.dataObj.refMap.get('box').clientWidth)*100+1)+"%";
            };
            this.dataObj.refMap.get('resize').setCapture && this.dataObj.refMap.get('resize').setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
            return false;
        };
    }
    //通过代码重新设置左右宽度
    public cfgWidth(options:any):void{
        this.dataObj.otherParams.lwidth=options.lwidth;
    }
}