import config from "@/utils/config";
import ApiHelper from "@/utils/api/ApiHelper";
import Axios from "@/utils/axios";
import UtilPub from "@/utils/UtilPub";
export default class OrderProviderApi extends ApiHelper{
    static buildUrl(url:string):string{
        if(config.isCloud)url=config.modelTypes.orderProvider+url;
        return url;
    }
    //查询所有的银行
    static async getAllBank(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/bank/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //查询所有的国家
    static async getAllCountry(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/country/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //构造区域树形结构数据
    static async getZoneTreeData(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/zone/custom"), params: Object.assign({operateType:'tree'},options.params) });
        return result.data;
    }
    //构建品牌下拉数据
    static async getAllBrand(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/brand/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //构建车型下拉数据
    static async getAllCx(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/cx/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //构建车型公告号下拉数据
    static async getAllCxggh(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/cxGgh/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //查询指定车型公告号的订单信息
    static async getCxgghOrderInfo(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/cxGgh/custom"), params: Object.assign({operateType:'getCxgghOrderInfo'},options.params) });
    }
    //构建客户下拉数据
    static async getAllCustomer(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/customer/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.disabled});
        })
        return data;
    }
    //查询可以用于支付的客户账户
    static async loadCanPay(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/customer/custom"), params: Object.assign({operateType:'loadCanPay'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.disabled});
        })
        return data;
    }
    //根据客户id查询客户的收货地址
    static async getCustomerDeliveryByCustomerId(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/customer/custom"), params: Object.assign({operateType:'getCustomerDeliveryByCustomerId'},options.params) });
        return this.buildSelectData(result);
    }
    //根据客户id查询客户的可用账号
    static async getCustomerAccountByCustomerId(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/customer/custom"), params: Object.assign({operateType:'getCustomerAccountByCustomerId'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.code,item:item,disabled:item.status==1});
        })
        return data;
    }
    //根据品牌和车型查找成品码下拉数据集
    static async getCpmByBrandAndCx(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/cpm/custom"), params: Object.assign({operateType:'getCpmByBrandAndCx'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.cpm,item:item,disabled:item.flag==1});
        })
        return data;
    }
    //重置客户账号密码
    static async resetCustomerAccount(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/customer/custom"), params: Object.assign({operateType:'resetCustomerAccount'},options.params) });
    }
    //获取指定车型的国家
    static async getCxCountry(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/cx/custom"), params: Object.assign({operateType:'getCxCountry'},options.params) });
        return this.buildSelectData(result);
    }
    //下架/上架某个配置项
    static async changeStatusConfig(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/cxGgh/custom"), params: Object.assign({operateType:'changeStatusConfig'},options.params) });
    }
    //删除配置
    static async deleteConfig(options:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/cxGgh/custom"), params: Object.assign({operateType:'deleteConfig'},options.params) });
    }
    //审核订单
    static async processOrder(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/order/custom"), params: Object.assign({operateType:'processOrder'},options.params) });
    }
    //构造pi模板下拉数据
    static async getAllPiTemplate(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/piTemplate/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //构造pi下拉数据
    static async getAllPi(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/pi/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        return this.buildSelectData(result);
    }
    //业务、财务审核PI
    static async processPi(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/pi/custom"), params: Object.assign({operateType:'processPi'},options.params) });
    }
    //意向订单转正式订单
    static async toFormalOrder(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/toFormalOrder/custom"), params: Object.assign({operateType:'toFormalOrder'},options.params) });
    }
    //意向订单作废
    static async invalidOrder(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/toFormalOrder/custom"), params: Object.assign({operateType:'invalidOrder'},options.params) });
    }
    //发布PI
    static async pubPi(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/pi/custom"), params: Object.assign({operateType:'pubPi'},options.params) });
    }
    //解锁PI
    static async unlockPi(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/pi/custom"), params: Object.assign({operateType:'unlockPi'},options.params) });
    }
    //pi变更提交审批
    static async piChangeSubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/piChange/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //查询已发布的PI
    static async getAllPubPi(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/piChange/custom"), params: Object.assign({operateType:'getAllPubPi'},options.params) });
        return this.buildSelectData(result);
    }
    //审核[通过、退回]确定事件
    static async processPiChange(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/piChange/custom"), params: Object.assign({operateType:'process'},options.params) });
    }
    //拆单的时候加载订单信息
    static async orderInfo(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/formalOrder/custom"), params: Object.assign({operateType:'orderInfo'},options.params) });
    }
    //拆单的时候加载订单信息
    static async produceInfo(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/formalOrder/custom"), params: Object.assign({operateType:'produceInfo'},options.params) });
    }
    //更改订单状态为不拆单
    static async notSplit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/formalOrder/custom"), params: Object.assign({operateType:'notSplit'},options.params) });
    }
    //根据订单id加载所有的子订单
    static async getChildOrdersByOrderId(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/formalOrder/custom"), params: Object.assign({operateType:'getChildOrdersByOrderId'},options.params) });
    }
    //为子订单分配订单专员
    static async fpddzy(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/formalOrder/custom"), params: Object.assign({operateType:'fpddzy'},options.params) });
    }
    //信用证提交审批
    static async xyzSubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xyz/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //审核[通过、退回]确定事件
    static async processXyz(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xyzProcess/custom"), params: Object.assign({operateType:'processXyz'},options.params) });
    }
    //信用证作废
    static async xyzInvalid(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xyz/custom"), params: Object.assign({operateType:'invalid'},options.params) });
    }
    //查询可交单的信用证集合
    static async xyzJdData(options?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/xyzJd/custom"), params: Object.assign({operateType:'xyzJdData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.code,item:item,disabled:item.status!=9});
        })
        return data;
    }
    //查询某个信用证下的订单集合
    static async xyzOrderData(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/xyzJd/custom"), params: Object.assign({operateType:'xyzOrderData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            // let bz=item.jsType==0?'(RMB)':'($)';
            // let label=item.code.substr(2)+'  ['+UtilPub.toAmountFormat(item.amount,false,2)+bz+']';
            // data.push({value:item.id,label:label,item:item,disabled:item.jdFlag==1});
            data.push({value:item.id,label:item.code.substr(2),item:item,disabled:item.jdFlag==1});
        })
        return data;
    }
    //查询可银行入账的信用证集合
    static async xyzYhrzData(options?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/xyzYhrz/custom"), params: Object.assign({operateType:'xyzYhrzData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.code,item:item,disabled:item.status!=10});
        })
        return data;
    }
    //信用证银行入账提交
    static async xyzYhrzSubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xyzYhrz/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //根据信用证明细id查询信用证id
    static async getXyzIdByDetailId(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/skqr/custom"), params: Object.assign({operateType:'getXyzIdByDetailId'},options.params) });
    }
    //收款确认
    static async skqrAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/skqr/custom"), params: Object.assign({operateType:'orderAction'},options.params) });
    }
    //收款复审
    static async skfsAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/skfs/custom"), params: Object.assign({operateType:'orderAction'},options.params) });
    }
    //订单解锁
    static async unlockOrderAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/unlockOrder/custom"), params: Object.assign({operateType:'orderAction'},options.params) });
    }
    //订单解锁复审
    static async unlockOrderFsAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/unlockOrderFs/custom"), params: Object.assign({operateType:'orderAction'},options.params) });
    }
    //订单结算
    static async ddjsAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/ddjs/custom"), params: Object.assign({operateType:'orderAction'},options.params) });
    }
    //构建中信保账户下拉数据
    static async getAllOaZxb(options?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/zxb/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.code,item:item});
        })
        return data;
    }
    //oa申请单提交
    static async oaApplySubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/oaApply/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //根据oaApply明细id查询oaApply的id
    static async getOaApplyIdByDetailId(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/oaApply/custom"), params: Object.assign({operateType:'getOaApplyIdByDetailId'},options.params) });
    }
    //tt发布
    static async ttSubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/tt/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //tt作废
    static async ttInvalid(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/tt/custom"), params: Object.assign({operateType:'invalid'},options.params) });
    }
    //tt支付提交
    static async ttPaySubmit(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/ttPay/custom"), params: Object.assign({operateType:'submit'},options.params) });
    }
    //根据tt支付明细id查询tt支付主id
    static async getTtPayIdByDetailId(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/ttPay/custom"), params: Object.assign({operateType:'getTtPayIdByDetailId'},options.params) });
    }
    //信保回款确认
    static async xbhkAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xbhk/custom"), params: Object.assign({operateType:'xbhkAction'},options.params) });
    }
    //信保回款复审
    static async xbfsAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/xbfs/custom"), params: Object.assign({operateType:'xbfsAction'},options.params) });
    }
    //------------------------------------------------------发运
    //查询所有货代公司
    static async getAllHdgs(options?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/hdgs/custom"), params: Object.assign({operateType:'getAllData'},options.params) });
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.gsmc,item:item});
        })
        return data;
    }
    //确定生成订舱委托书
    static async genDcwtsAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/hdxx/custom"), params: Object.assign({operateType:'genDcwtsAction'},options.params) });
    }
    //确定生成启票通知单
    static async genQptzdAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/qp/custom"), params: Object.assign({operateType:'genQptzdAction'},options.params) });
    }
    //确认发货通知
    static async qrfhtzAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/hdxx/custom"), params: Object.assign({operateType:'qrfhtzAction'},options.params) });
    }
    //清关
    static async qgAction(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/hdxx/custom"), params: Object.assign({operateType:'qgAction'},options.params) });
    }
}