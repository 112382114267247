import BaseBean from "@/utils/BaseBean";

export interface ICardFormItemDataObj {
    utilInst:CardFormItemUtil
    otherParams:any
}

export default class CardFormItemUtil extends BaseBean{
    public dataObj:ICardFormItemDataObj;
    public props:any;
    public context:any;

    constructor(proxy:any,dataObj:ICardFormItemDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public async init():Promise<any>{

    }
}