<template>
    <div id="app">
        <el-config-provider :locale="locale">
            <router-view v-slot="{ Component }">
                <transition :name="animationName">
                    <keep-alive>
                        <component :is="Component"/>
                    </keep-alive>
                </transition>
            </router-view>
        </el-config-provider>
        <el-dialog :title="dialogTitle" v-model="noteDialog" :append-to-body="true" draggable>
            <v-html>{{dialogContent}}</v-html>
        </el-dialog>
    </div>
</template>

<script>
    import {reactive, toRefs, defineComponent, getCurrentInstance, onMounted, computed, provide} from 'vue';
    import zhCn from 'element-plus/lib/locale/lang/zh-cn'
    import en from 'element-plus/lib/locale/lang/en'

    import {useRoute} from "vue-router";
    const route = useRoute()
    const currentRoute = computed(() => route.path)
    export default defineComponent({
        name: 'App',
        setup(props, context) {
            const {proxy}=getCurrentInstance();
            provide('app', proxy);
            const utils=proxy.utils;
            let dataObj = reactive({
                noteDialog:false,
                dialogTitle:'提示',
                dialogContent:'',
                helpParam:0,
                //fade-transform,el-zoom-in-center,el-fade-in
                animationName: !localStorage.getItem('routeAnimation') ? 'fade-transform' : localStorage.getItem('routeAnimation')
            });
            onMounted(()=>{
                //把app实例挂到Tools.ctx.appInst上，这样在任何地方使用Tools.ctx.appInst都可以得到app的实例
                //比如后台通过websocket推送消息到前端，如果msgType=3，就可以打开app这个页面的弹出框，给出一些提示信息
                utils.Tools.ctx.appInst=proxy;
            })
            const locale = computed(() => {
                dataObj.helpParam++;
                let currentLang=localStorage.getItem('currentLang');
                if(currentLang=='en')return en;
                else return zhCn;
            })
            return {
                ...toRefs(dataObj),locale
            }
        }
    });
</script>

<style lang="scss" src="./style/app.scss"/>

<!--<style scoped src="../../style/login.scss" lang="scss"/>-->

