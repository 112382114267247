import BaseBean from "@/utils/BaseBean";
import {UploadFile, UploadFiles, UploadRawFile, UploadRequestOptions} from "element-plus/lib/components";
import {inject,nextTick} from "vue";
import config from "@/utils/config";
import {ElementLoading} from "element-plus/lib/components/loading/src/directive";

export interface IUploadDataObj {
    utilInst:UploadUtil
    refMap:Map<string,any>
    resultMsg:string
    uploadDialogVisible:boolean//点击文件，让文件在弹出框中显示，主要应用于图片，showInDialog为true的时候，该参数的配置才有意义
    dialogImageUrl:string//在弹出框中显示的文件
    fileList:Array<any>,
    uploadParams:IUploadParams
    uploadResultDrawer:boolean
    otherParams:any
}
export default class UploadUtil extends BaseBean{
    public dataObj:IUploadDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IUploadDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }

    //文件上传失败钩子函数
    public async error(error:any,file:any, fileList:UploadFiles):Promise<void>{
        console.log(error,file,fileList);
    }
    public asyncImgChecked(file:UploadRawFile):void{
        let reader = new FileReader()
        // reader.readAsDataURL(file.raw) // 必须用file.raw
        // reader.onload = () => { // 让页面中的img标签的src指向读取的路径
        //     let img:any = new Image()
        //     img.src = reader.result
        //     // console.log(`1当前文件尺寸大小：${img.width}×${img.height}`)
        //     if (img.complete) { // 如果存在浏览器缓存中
        //         console.log('1 width:'+img.width+' height:'+img.height)
        //     } else {
        //         img.onload = () => {
        //             console.log('2 width:'+img.width+' height:'+img.height)
        //         }
        //     }
        // }
    }
    //上传文件之前的钩子。若返回false或者返回 Promise 且被 reject，则停止上传。(返回false的时候，会调用下方的beforeRemove和remove)
    public async beforeUpload(file:UploadRawFile):Promise<boolean>{
        const img = new Promise((resolve, reject)=> {
            let _URL = window.URL || window.webkitURL;
            let img = new Image();
            img.src = _URL.createObjectURL(file);
            img.onload =()=>{
                resolve(img);
            }
        })

        // let _URL = window.URL || window.webkitURL;
        // let img = new Image();
        // img.src = _URL.createObjectURL(file);
        // img.onload =()=>{
        //     console.log(img.width,img.height);//获取宽和高，只能在onload里面，否则获取不到,所以一般会采取上面promise的写法
        // }
        // return false;
        if(!await this.props.beforeUpload(file,img,this.proxy))return false;//如果业务模块自己有定义上传之前函数，则调用
        if(file.name.length>90){//上传文件的名称是否超过最大长度
            this.proxy.$message.error(this.proxy.$t('upload.fileNameLimit'));
            return false;
        }
        const isLarge = file.size / 1024 / 1024 < (this.dataObj.uploadParams.fileSize as number)
        if (!isLarge) {
            //即便这里返回false停止上传了，仍然会进入beforeRemove和handleRemove里面去
            this.proxy.$message.error(this.proxy.$t('upload.fileSizeLimit')+this.dataObj.uploadParams.fileSize+'M');
            return false;
        }
        return true;
    }
    //自定义上传文件
    public async doUpload(params:UploadRequestOptions):Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0,0,0,0.7)'});
        let file = params.file;
        if(this.dataObj.uploadParams.saveType==1){//上传到阿里云
            let type=config.type?config.type:'product';//存储到阿里云的时候，第一级目录是项目名称，第二级目录是本地、测试、生产环境，第三级目录是上传文件类别标识
            let aliCloudPre=this.dataObj.uploadParams.aliCloudPre?this.dataObj.uploadParams.aliCloudPre:config.aliCloudPre;
            //A/B/C/file.jpg，那么阿里云会建立A/B/C三层目录，然后在最里层目录下放file.jpg（默认会以日期建立目录）
            let uploadName =  `${aliCloudPre}/${type}/${this.dataObj.uploadParams.uploadType}/${this.utils.UtilPub.formatDate(new Date(),'/')}/` + this.utils.UtilPub.guid()+file.name.substr(file.name.indexOf('.'));
            if(this.dataObj.uploadParams.belongMaxId){//如果传了主id，那么会把文件按主id创建目录
                uploadName =  `${aliCloudPre}/${type}/${this.dataObj.uploadParams.uploadType}/${this.dataObj.uploadParams.belongMaxId}/` + this.utils.UtilPub.guid()+file.name.substr(file.name.indexOf('.'));
            }
            try {
                await this.utils.AliOss.doUpload({uploadName: uploadName, file: file, loading: loading, proxy: this.proxy});
            } catch (e) {
                console.log(e);
                loading.close();
            }
        }else{//没有上传到云，自己处理上传文件
            await this.buildParamsSelf(params,loading);
        }
    }
    //构建参数---云处理上传文件(在aliyun.oss.ts的上传成功那里调用)
    public async buildParamsYun(res:any,uploadName:string,params:UploadRequestOptions,loading:ElementLoading):Promise<void>{
        let serverPath=res.requestUrls[0];//文件在阿里云上的路径
        //tmd，有时候上传返回的结果后面带上了?uploadId
        if(serverPath.indexOf('?')>-1)serverPath=res.requestUrls[0].substr(0,res.requestUrls[0].indexOf('?'));
        let formData={
            uid:(params.file as any).uid+'',
            preName:params.file.name,
            nowName:uploadName,
            path:serverPath,
            type:this.dataObj.uploadParams.type+'',
            belongMaxId:this.dataObj.uploadParams.belongMaxId,
            belongMinId:this.dataObj.uploadParams.belongMinId,
            uploadType:this.dataObj.uploadParams.uploadType+'',
            fileSize:params.file.size+'' // fileSize:file.size / 1024 / 1024,//(兆)
        }
        await this.props.buildUploadParams(formData,params,this.proxy);//业务模块构建传到后台的参数
        await this.saveUploadInfo({formData:formData,loading:loading});
    }
    //构建参数---自己处理上传文件
    public async buildParamsSelf(params:UploadRequestOptions,loading:ElementLoading):Promise<void>{
        let formData:FormData = new FormData();
        formData.append('uid',(params.file as any).uid);//上传的文件
        formData.append('commonFile',params.file);//上传的文件
        formData.append('preName',params.file.name);//文件原名
        formData.append('belongMaxId',this.dataObj.uploadParams.belongMaxId as string);//上传文件归属大类
        formData.append('belongMinId',this.dataObj.uploadParams.belongMinId as string);//上传文件归属小类
        formData.append('uploadType',this.dataObj.uploadParams.uploadType as string);//上传类别，默认是commonFile，其它的可以根据业务自己命名，注意两个特殊的：billAttach、imgSources
        formData.append('type',this.dataObj.uploadParams.type+'');//上传类别
        formData.append('fileSize',params.file.size+'');//文件大小
        await this.props.buildUploadParams(formData,params,this.proxy);//业务模块构建传到后台的参数
        await this.saveUploadInfo({formData:formData,loading:loading});
    }
    //保存上传信息到数据库（如果文件服务器是自己维护，那么options里面会包括文件流，如果阿里云充当文件服务器，那么options只包含上传成功之后的url地址）
    public async saveUploadInfo(options:any):Promise<void>{
        let res:any,uid:any;
        if(this.dataObj.uploadParams.saveType==1){//如果采用云存储文件，那么文件处理其实就是数据库存储文件信息，所以任何一台处于集群中的机器都可以处理
            //如果url有值，则自己处理上传回传信息，在saveUploadFiles里有判断
            res=await this.utils.Api.saveUploadFiles({url:this.dataObj.uploadParams.action,formData:options.formData});
            uid=options.formData.uid;
        }else{//不是采用云存储，则要么需要独立服务器来处理、要么是指定路径让controller来处理，比如解析excel的数据
            let jsonParams={} as any;
            for (let [key, value] of options.formData) {
                if(typeof value!="object") jsonParams[key]=value;
                if('uid'==key) uid=value;
            }
            if(this.dataObj.uploadParams.action){//自己指定了处理地址
                //在后端接收参数处理的时候，除了文件commonFile之外，其它参数统一放到jsonParams字符串里面
                options.formData.append('jsonParams',JSON.stringify(jsonParams));//设置参数
                res=await this.utils.Api.speUploadFile(Object.assign(this.dataObj.uploadParams,{formData:options.formData}));
            }else{//自己未自定处理地址
                res=await this.utils.Api.uploadFile({formData:options.formData});
            }
        }
        options.loading.close();
        if(this.context.attrs['uploadResult']){//自定义处理上传结果
            (this.context.attrs['uploadResult'] as Function)(res);
        }else{//组件默认结果处理
            if(!res.result){//上传失败之后，会显示操作失败提醒，如果还传入了errorData，那么除了显示操作失败之后，还会打开抽屉显示具体失败信息
                this.utils.Tools.info({message: (res.msg?res.msg:this.proxy.$t('upload.fail'))});
                if(res.errorData){
                    this.dataObj.resultMsg=res.errorData;
                    this.dataObj.uploadResultDrawer=true;
                }
            }else{
                this.utils.Tools.success({message: (res.msg?res.msg:this.proxy.$t('upload.success'))});
                if(res.uploadFiles){
                    //上传成功之后，组件的file虽然呈现出来了，但是这个file并不是从后台查询出来的file对象，还没有id属性，
                    // 而且该file的url还是本地的url，应该把它替换为服务器url
                    //如果你点击它进行下载就会报错，所以我们需要循环一下返回值，把id给file赋值上去
                    this.dataObj.fileList.forEach((item:any)=>{
                        if(!item.id){//没有id，肯定是刚才上传的
                            let uploadFiles=res.uploadFiles;//其实只会有一条记录，当选择多个文件上传的时候，其实是一个一个请求上传的
                            for(let i=0;i<uploadFiles.length;i++){
                                // if(uploadFiles[i].preName==item.name){//这样判断不保险，最好用uid来判断
                                if(uid==item.uid){//这样判断不保险，最好用uid来判断
                                    item.id=uploadFiles[i].id;
                                    item.url=uploadFiles[i].path;break;
                                }
                            }
                        }
                    })
                }
            }
            //抛出上传完成钩子给业务组件,把proxy传出去是因为，有可能外部外部是循环构造上传组件，于是组件的ref是id，不好拿，于是把proxy传出去
            this.props.afterResult(res,options,this.proxy);
        }
    }

    //删除文件之前的钩子，若返回 false 或者返回 Promise 且被 reject，则停止删除。注意：下面的resolve(true/false)都可以删除成功，
    //也就是说只要resolve()都可以删除成功，reject一样的道理，只要reject都会失败；因为括号里面的代表的是参数，不是是否成功的标识
    public async beforeRemove(file:UploadFile):Promise<boolean>{
        // return this.proxy.$confirm(`确定要删除 ${file.name} 吗?`);
        if (file.status!='success')return true;
        if(!this.dataObj.uploadParams.canDelFile){
            this.proxy.$message(this.proxy.$t('upload.canNotDel'));return false;
        }
        //万一我们自己定义文件显示，比如下面有输入框，当要删除输入框内容的时候，由于按了backspace，于是也会调用该方法，于是要判断一下到底是不是在删除图片
        let target:any=window.event;
        if(!(target.target.tagName=='I' || target.target.tagName=='LI' || target.target.tagName=='svg'))return false;
        return new Promise((resolve, reject) => {
            this.proxy.$confirm(this.proxy.$t('upload.delTipMsg'), this.proxy.$t('upload.delTip'), {
                confirmButtonText: this.proxy.$t('confirm'),
                cancelButtonText: this.proxy.$t('cancel'),
                type: 'warning'
            }).then(async () => {
                let result = await this.props.beforeRemove(file, this.dataObj.fileList);
                if(result)resolve(true);
                else reject(false);
            }).catch(() => {
                this.proxy.$message({type: 'info',message: this.proxy.$t('tools.cancelOperate')});
                reject(false)
            });
        });
    }
    //删除文件
    public async remove(file:UploadFile):Promise<void>{
        const loading = this.proxy.$loading({lock: true,text: this.proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
        // const isLarge = file.size / 1024 / 1024 < (this.dataObj.uploadParams.fileSize as number);
        //当上传的文件超过指定大小之后，el-upload组件会自动调用on-remove来删除本次上传的超标文件，于是上传超标文件自动会进入这里，
        //对于上传的超过指定大小文件而到这里的操作，我们没有必要往后台发送请求，所以判断了一下
        //你可以通过判断文件大写来区分是我们手动删除，还是系统不满足而自动调用的删除操作；还有一种办法是看文件的状态来区分，如果是超标而由系统发起
        //的删除，那么文件的状态肯定不是success，但是我们手动发起的删除，文件的状态肯定是success
        if (file.status=='success') {
           nextTick(async ()=>{
               // @ts-ignore
               let delItem:any=this.dataObj.fileList.find((item:any)=>{return item.uid==file.uid || item.id==file.id});
               // @ts-ignore
               let res = await this.utils.Api.deleteFile({id:(file.id?file.id:delItem.id)});
               if(res.result){
                   // this.dataObj.fileList=this.dataObj.fileList.filter((item:any)=>{return item.id!=file.id && item.uid!=file.uid});
                   this.dataObj.fileList=this.dataObj.fileList.filter((item:any)=>{return item.uid!=file.uid});
                   this.utils.Tools.success();
                   await this.props.afterRemove(file, this.dataObj.fileList);//抛出删除之后钩子
               }
           })
        }
        loading.close();
    }
}