import Crypoto from "@/utils/EnDecypt";
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import utils from './Jh.core'
export default class UtilPub {
    constructor(){

    }
    /*字符串加密*/
    public static encrypt(txt:string):string{
        let crypoto:Crypoto=new Crypoto();
        return crypoto.encrypt(txt);
    }
    /*字符串解密*/
    public static decrypt(txt:string):string{
        if(!txt)return '';
        let crypoto:Crypoto=new Crypoto();
        return crypoto.decrypt(txt);
    }
    //随机数
    public static guid():string{
        return  'xxxxxxxx-xxxx' .replace(/[xy]/g, (c)=>{
            let  r = Math.random()*16|0, v = c ==  'x'  ? r : (r&0x3|0x8);
            return  v.toString(16);
        });
    }
    public static randomString(length:number):string{
        let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = length; i > 0; --i)
            result += str[Math.floor(Math.random() * str.length)];
        return result;
    }
    //格式化为yyyy-mm-dd的格式
    public static formatDate(date:string,join:string):string{
        if(!date)return '';
        let d = new Date(date),month = '' + (d.getMonth() + 1),day = '' + d.getDate(),year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if(!join)join='-';
        return [year, month, day].join(join);
    }
    public static getCurrentDate(join:string):string{
        // 创建一个Date对象
        let currentDate = new Date();
        // 获取年份
        let year = currentDate.getFullYear();
        // 获取月份（注意，月份是从0开始计数的，所以需要加1，并在不足两位数时前面补0）
        let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        // 获取日期（在不足两位数时前面补0）
        let day = currentDate.getDate().toString().padStart(2, '0');
        if(!join)join='';
        return [year, month, day].join(join);
    }
    //指定日期之后几天
    public static getAfterDayDate(dateStr:string,numDay:number):string{
        let date:any=new Date(dateStr);
        date=date.setDate(date.getDate()+numDay);
        date=new Date(date);
        return [date.getFullYear(), date.getMonth()+1, date.getDate()].join('-');
    }
    //指定日期之后几月
    public static getAfterMonthDate(dateStr:string,numMonth:number):string{
        let date:any=new Date(dateStr);
        date=date.setMonth(date.getMonth()+numMonth);
        date=new Date(date);
        return [date.getFullYear(), date.getMonth()+1, date.getDate()].join('-');
    }
    //指定日期之后几年
    public static getAfterYearDate(dateStr:string,numYear:number):string{
        let date:any=new Date(dateStr);
        date=date.setFullYear(date.getFullYear()+numYear);
        date=new Date(date);
        return [date.getFullYear(), date.getMonth()+1, date.getDate()].join('-');
    }

    public static isNotEmpty(strInput:any):boolean{
        return !(strInput == null || (strInput + "") == "" || !strInput);
    }
    public static isEmpty(strInput:any):boolean{
        return strInput == null || (strInput + "") == "" || !strInput;
    }
    public static isWangEditEmpty(str:any):boolean{
        let num = 0,reg = /<p>(&nbsp;|&nbsp;\s+)+<\/p>|<p>(<br\/>)+<\/p>/g;
        while (num < str.length && str != "")
        {
            num++;
            let k = str.match(reg);
            if (k) {
                str = str.replace(k[0], "");
            }
        }
        return str == "";
    }
    public static isObjectEqual(a:any,b:any):boolean{
        if(!a || !b)return false;
        if(Object.keys(a).length!=Object.keys(b).length)return false;
        if(Object.keys(a).length==0)return false;
        let aProps = Object.getOwnPropertyNames(a);//拿到两个对象的所有key
        let bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {//看长度是否相等
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];
            if(propName=='__ob__')continue;//不去掉这个会有死循环
            let propA = a[propName];
            let propB = b[propName];
            if ((typeof (propA) === 'object')) {
                let result=this.isObjectEqual(propA, propB);
                //为true的时候不能return true,因为后面的对象可能还没判断，只是前面的对象相等，后面可能不等呢，所以只返回不相等的情况
                if(!result){
                    return false;
                }
            } else if (propA !== propB) {
                return false
            }
        }
        return true
    }

    public static delItemFromArr(arr:any,delItem:any):void{
        let index=arr.findIndex((item:any) => this.isObjectEqual(item,delItem));
        if(index!=-1){
            arr.splice(index,1);
        }
    }

    public static phoneValidate(value:string):boolean{
        const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
        const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/
        return (reg1.test(value) || reg2.test(value));
    }

    public static checkPhone(rule:any, value:string, callback:any,required:boolean):void{
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg1 = /^0[1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9][1|2|3|4|5|6|7|8|9]-\d{8}$/
            const reg2 = /^1[3|4|5|7|8|9][0-9]\d{8}$/

            if (reg1.test(value) || reg2.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    }

    public static checkMobilePhone(rule:any, value:string, callback:any,required:boolean):void{
        if (!value) {
            if(required==false){
                callback()
            }else{
                callback("请输入电话号码")
            }
        } else {
            const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
            if (reg.test(value)) {
                callback()
            } else {
                return callback(new Error('请输入正确的电话号码'))
            }
        }
    }
    //----------------------------------------animate动画--------------------------------
    //表单Animate动画
    public static formAnimate():any{
        let animate:any={animate__animated:true};
        animate[UtilPub.randomFadeinAnimateStr()]=true;
        return animate;
    }
    //layerAnimate动画
    public static randomLayerAnimate():any{
        let animate:any={animate__animated:true};
        animate[UtilPub.randomAnimateStr()]=true;
        return animate;
    }
    //表格列Animate动画
    public static randomGridColAnimate():string{
        return 'animate__animated '+UtilPub.randomAnimateStr();
    }
    //表格内容Animate动画
    public static randomAnimateStr():string{
        let arr=new Array(18);arr.fill(false);
        let randIndex=Math.floor(Math.random() * arr.length);
        let baseClass=[
            'animate__swing',
            'animate__bounceInDown',
            'animate__bounceInRight',
            'animate__bounceInUp',
            'animate__fadeInDown',
            'animate__fadeInRight',
            'animate__flipInX',
            'animate__flipInY',
            'animate__lightSpeedInRight',
            'animate__rotateIn',
            'animate__rotateInDownLeft',
            'animate__rotateInDownRight',
            'animate__rotateInUpRight',
            'animate__rollIn',
            'animate__slideInDown',
            'animate__slideInUp',
            'animate__slideInLeft',
            'animate__slideInRight'
        ];
        return baseClass[randIndex];
    }
    //进入animate
    public static randomFadeinAnimateStr():string{
        let arr=new Array(6);arr.fill(false);
        let randIndex=Math.floor(Math.random() * arr.length);
        let baseClass=[
            'animate__fadeInRight',
            'animate__fadeInLeft',
            'animate__fadeInBottomRight',
            'animate__fadeInBottomLeft',
            'animate__fadeInTopLeft',
            'animate__fadeInTopRight'
        ];
        return baseClass[randIndex];
    }
    //----------------------------------------animate动画--------------------------------
    //防抖
    public static debounce(callback:Function,wait:number=800):any{
        let timer:any = null;
        let exFn= () => {
            timer&&clearTimeout(timer);
            timer = setTimeout(callback, wait)
        };
        exFn();
    }
    //打开弹出来
    public static async openDialog(options:any):Promise<void>{
        options=Object.assign({},{
            dialogDiv:UtilPub.randomString(6),
            fullscreen:false,
            showFooterBtn:true,
            contentHeight:'400px',
            ownerComp:options.proxy
        },options);
        //如果全屏的化，如果不设置弹出框内容高度，那么显示不好看，因为默认只有400，如果弹出框里面显示的是一个列表页，
        //列表内容如果很多的话，会被挤在弹出框中间显示，不好看，所以如果弹出框全屏，我们需要把弹出框内容的高度给计算一下。
        //本来dialog的title只有44，但是我还是减去了94，因为这样看起来好看一些（可能是dialog的padding这些造成的）
        if(options.fullscreen)options.contentHeight=(window.innerHeight-94)+'px';
        let vm = await options.proxy.utils.DialogApp.create(options);
        vm.dialogVisible = true;
    }

    //构建表单某项的验证规则
    public static commonValidRule(message:string):TCommonRuleItem{
        return {required: true, message: message, trigger: "blur"}
    }
    //根据传入的属性构造整个表单的验证规则
    public static commonFormValidRule(formRules:Array<TCommonObj>):TFormRule{
        let result:TFormRule={};
        formRules.forEach((formItem:TCommonObj)=>{
            let keys:Array<string>=Object.keys(formItem);
            for(let i=0;i<keys.length;i++){
                result[keys[i]]=[UtilPub.commonValidRule(formItem[keys[i]])];
            }
        }) 
        return result;
    }
    //为对象动态添加属性和值
    public static addObjProp(obj:any,propName:string,propValue:any=''):any{
        Object.defineProperty(obj, propName, {
            value: propValue,
            writable: true,
            enumerable: true,
            configurable: true
        });
    }
    public static formatterStatus(row:any, column:TableColumnCtx<any>, value:any, index:number):string{
        if (0 == value) {
            return utils.Tools.ctx.$t('normal');
        } else if (1 == value) {
            return utils.Tools.ctx.$t('stop');
        }else {
            return "";
        }
    }
    public static formatterYesNoStatus(row:any, column:TableColumnCtx<any>, value:any, index:number):string{
        if (0 == value) {
            return utils.Tools.ctx.$t('yes');
        } else if (1 == value) {
            return utils.Tools.ctx.$t('no');
        }else {
            return "";
        }
    }
    //首字母大写
    public static upFirst(str:string):string{
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    public static trim(str:string):any{
        str = str + "";
        return str.replace(/(^\s*)|(\s*$)/g, "");
    }
    public static replaceAll(s1:any, s2:any, s3:any):any{
        var r = new RegExp(s2.replace(/([\(\)\[\]\{\}\^\$\+\-\*\?\.\"\'\|\/\\])/g, "\\$1"), "ig");
        return s1.replace(r, s3);
    }
    public static isNumber(inputString:any):any{
        return inputString.match(/^-?([1-9]\d*\.\d*|[1-9]\d*|0\.\d*[1-9]\d*|0?\.0+|0)$/) != null;
    }
    public static string(str:any, length:any):any{
        var result = "";
        for (var i = 1; i <= length; i++) {
            result += str;
        }
        return result;
    }
    public static to_double(inputString:any):any{
        if (inputString == null || (inputString + "").length == 0) {
            return 0.0;
        }
        var tmpvar = parseFloat((inputString + "").replace(/\,/g, ""));
        if (isNaN(tmpvar)) {
            return 0.0;
        } else {
            return tmpvar;
        }
    }
    public static FormatNumber(strSrc:any, lngDotLength:any):any{
        lngDotLength = parseInt(lngDotLength);
        if (isNaN(lngDotLength)) lngDotLength = 2;

        strSrc = UtilPub.to_double(strSrc) + "";

        var nTen;
        var lngSrcLength = strSrc.length;
        var dotPos = strSrc.indexOf(".", 0);
        var result = "";
        if (dotPos == -1) {//参数没有小数
            if (lngDotLength > 0)
                result = strSrc + "." + UtilPub.string("0", lngDotLength);
            else result = strSrc;
        } else if ((lngSrcLength - dotPos - 1) < lngDotLength) {
            //参数本身有小数,但是小数比要求的短
            result = strSrc + UtilPub.string("0", lngDotLength - lngSrcLength + dotPos + 1);
        } else {
            //小数比要求的长
            nTen = Math.pow(10, lngDotLength);
            result = Math.round(parseFloat(strSrc) * nTen) / nTen + "";
            //可能由于四舍五入导致小数位数不够
            var dotPos1 = result.indexOf(".", 0);
            if (dotPos1 == -1){//result=51.004800这种保留两位小数的情况，经过上面的出来result就变成了51，这个时候不会进入下方执行了
                result += "." + UtilPub.string("0", lngDotLength);
                return result;
            }


            var lngSrcLength1 = result.length;
            if (lngSrcLength1 - dotPos1 - 1 != lngDotLength) {
                if (dotPos1 == -1) {
                    if (lngDotLength > 0)
                        result += "." + UtilPub.string("0", lngDotLength);
                } else {
                    // @ts-ignore
                    result += String("0", lngDotLength - lngSrcLength1 + dotPos1 + 1);
                }
            }
        }

        return result;
    }
    public static leftStr(str:any, lngL:any):any{
        if (lngL > str.length) lngL = str.length;
        return str.substr(0, lngL);
    }
    public static rightStr(str:any, lngL:any):any{
        return str.substr(str.length - lngL, lngL);
    }
    public static AmountToNumber(amountString:any):any{
        if (!amountString) amountString = "";
        amountString = amountString + "";
        if (amountString == "") amountString = "0.00";

        amountString = amountString.replace(/,/g, "");
        return UtilPub.to_double(amountString);
    }
    public static toAmountFormat(amountNumber:any, blnZeroToEmpty:any, dotLen:any):any{
        dotLen = parseInt(dotLen);
        if (isNaN(dotLen)) dotLen = 2;
        amountNumber = UtilPub.FormatNumber(UtilPub.AmountToNumber(amountNumber), dotLen);
        var strS = ",";
        var plen;
        if (dotLen > 0) plen = dotLen + 1;
        else plen = 0;
        var strR = UtilPub.rightStr(amountNumber, plen);
        var strL = UtilPub.leftStr(amountNumber, amountNumber.length - plen);

        //在整数部分插入分隔符
        var result = "";
        var j = 0;
        for (var i = strL.length; i >= 0; i--) {
            result = strL.charAt(i) + result;
            if ((j != 0) && ((j % 3) == 0)) result = strS + result;
            j++;
        }
        //加上小数点部分
        result = result + strR;
        if (UtilPub.leftStr(result, 1) == strS) result = UtilPub.rightStr(result, result.length - 1);
        result = result.replace(/^-,*/g, "-");


        if (blnZeroToEmpty == null) blnZeroToEmpty = true;
        if (blnZeroToEmpty && result == "0.00") {
            return "";
        } else {
            return result;
        }
    }
    //数字转英文大写金额--------------------------------began
    //英文整数大写
    public static convertToEnglish(num:any):any{
        const numbersToWords = [
            '', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE',
            'TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'
        ];
        const tensToWords = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

        if (num < 20) {
            return numbersToWords[Math.floor(num)];
        } else if (num < 100) {
            return tensToWords[Math.floor(num / 10)] + ' ' + numbersToWords[Math.floor(num % 10)];
        } else if (num < 1000) {
            return numbersToWords[Math.floor(num / 100)] + ' HUNDRED ' + (num % 100 === 0 ? '' : 'AND ') + this.convertToEnglish(num % 100);
        } else if (num < 1000000) {
            return this.convertToEnglish(Math.floor(num / 1000)) + ' THOUSAND ' + (num % 1000 === 0 ? '' : this.convertToEnglish(num % 1000));
        } else if (num < 1000000000) {
            return this.convertToEnglish(Math.floor(num / 1000000)) + ' MILLION ' + (num % 1000000 === 0 ? '' : this.convertToEnglish(num % 1000000));
        } else {
            return 'Number is too large to convert';
        }
    }
    //英文小数大写
    public static convertDecimalToWords(decimal:any):any{
        const firstDigit = Math.floor(decimal * 10);
        const secondDigit = Math.floor((decimal * 100) % 10);

        //TMD,用下面这种恶心
        let first=this.convertToEnglish(firstDigit*10);
        if(secondDigit!=0){
            return first+'-'+this.convertToEnglish(secondDigit);
        }else return first;
    }
    //转成英语大写
    public static toEnBigAmount(num:any,lanType:number=0):any{
        num = (num + "").replace(/\,/g, "");
        if(num<=0)return '';
        const integerPart = Math.floor(num);
        const decimalPart = (num - integerPart).toFixed(2);
        let bz='US DOLLARS ';//币种
        if(lanType==1)bz='CNY ';
        if(decimalPart=='0.00')return 'SAY TOTAL '+bz+this.convertToEnglish(integerPart) + ' ONLY';
        return 'SAY '+bz+' DOLLARS '+this.convertToEnglish(integerPart) + ' AND POINT ' + this.convertDecimalToWords(decimalPart)+' ONLY';
    }
    //数字转英文大写金额--------------------------------end

    //数字转中文大写金额--------------------------------began
    //得到金额单位，最后一位元需要自动加入,iLength 当前数字是第几位,从个位开始
    public static amountUnit(iLength:any):any{
        let r;
        let mod = iLength % 4;
        switch (mod) {
            case 0:r = "仟";break;
            case 2:r = "拾";break;
            case 3:r = "佰";break;
            default:
                switch (iLength) {
                    case  1:r = "元";break;
                    case  9:r = "亿";break;
                    case 17:r = "兆";break;
                    default:r = "万";
                }
        }
        return r;
    }
    //将小写数字字符转换为大写,inputString 要转换的数字;返回值: 转换后的字符串
    public static caPschar(inputString:any):any{
        let r;
        switch (inputString) {
            case "0":r = "零";break;
            case "1":r = "壹";break;
            case "2":r = "贰";break;
            case "3":r = "叁";break;
            case "4":r = "肆";break;
            case "5":r = "伍";break;
            case "6":r = "陆";break;
            case "7":r = "柒";break;
            case "8":r = "捌";break;
            case "9":r = "玖";break;
            default:r = "";
        }
        return r;
    }
    //将小写金额转换为大写,inputString 要转换的数字
    public static toZhBigAmount (inputString:any):any {
        if (inputString == undefined) return "";
        inputString = (inputString + "").replace(/\,/g, "");
        let instring = "" + inputString;
        if (!instring) return "";

        let j = instring.indexOf(".");
        let strDot = "";
        //小数部分
        let strInt = instring;
        //整数部分
        let strDotCAPS = "";
        //小数部分(返回)
        let strIntCAPS = "";
        //整数部分(返回)
        let strResult = "";
        if (j > 0) {
            strDot = instring.slice(j + 1);
            //得到小数部分(设置为两位)
            strInt = instring.substring(0, j);
            //得到整数部分
            //两位小数
            if (strDot.length > 2) {
                strDot = strDot.substring(0, 2);
            }
            if (strDot.substring(1, 2) == "0") {
                strDot = strDot.substring(0, 1);
                if (strDot.substring(0, 1) == "0") strDot = "";
            }
        }
        //角,分
        if (strDot.length == 2) {
            strDotCAPS = UtilPub.caPschar(strDot.substring(0, 1)) + "角" +
                UtilPub.caPschar(strDot.substring(1, 2)) + "分";
        } else if (strDot.length == 1) {
            strDotCAPS = UtilPub.caPschar(strDot.substring(0, 1)) + "角";
        } else {
            strDotCAPS = "整";
        }
        //整数部分
        for (var i = 0; i < strInt.length; i++) {
            var strTemp = strInt.substring(i, i + 1);
            var iLen = strInt.length - i;
            if (strTemp == "-" && i == 0) {
                strIntCAPS = "负";
            } else {
                strIntCAPS = strIntCAPS + UtilPub.caPschar(strTemp) + UtilPub.amountUnit(iLen);
            }
        }
        //整数末尾如果没有元则加上
        if (UtilPub.trim(strIntCAPS) != "" && strIntCAPS.lastIndexOf("元") == -1)strIntCAPS = strIntCAPS + "元";
        if (strDot != "" && strInt.substring((strInt.length - 2), strInt.length) == "00")strIntCAPS = strIntCAPS + "零";
        //返回值
        strResult = strIntCAPS + strDotCAPS
        //对返回值作处理
        if (strInt != "0") {
            strResult = strResult.replace(/零仟|零佰|零拾|零角|零分/g, "零");
        } else {
            strResult = strResult.replace(/零仟|零佰|零拾|零角|零分/g, "");
        }
        //去掉连续的零
        while (strResult.indexOf("零零") != -1) {
            strResult = strResult.replace(/零零/g, "零");
        }
        strResult = strResult.replace(/零元/g, "元");
        //去掉首尾的零
        strResult = strResult.replace(/(^零*)|(零*$)/g, "");
        strResult = strResult.replace(/零兆零亿零万/g, "兆");
        strResult = strResult.replace(/零兆零亿/g, "兆");
        strResult = strResult.replace(/零兆零万/g, "兆");
        strResult = strResult.replace(/零亿零万/g, "亿");
        strResult = strResult.replace(/零兆/g, "兆");
        strResult = strResult.replace(/零亿/g, "亿");
        strResult = strResult.replace(/零万/g, "万");
        strResult = strResult.replace(/负元/g, "负");
        strResult = strResult.replace(/亿万/g, "亿");
        strResult = strResult.replace(/^元*/g, "");
        //OK
        if (strResult == "整") {
            return "";
        } else {
            return  strResult;
        }
    }
    //数字转中文大写金额--------------------------------end
}