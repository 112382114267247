import UtilPub from "@/utils/UtilPub";
export default class BillComponent {
    //根据单据类型得到单据组件（暂时废掉了，用下方的getComponentByBillType）
    static buildComponent(options:any):Promise<any>{
        return new Promise((resolve,reject) =>{
            //待办工作中有个待审批业务，那里有模块id（也是单据类型），要根据不同模块id打开不同的单据，所以这里要根据模块id来找到对应模块
            //在开发单据的时候，有多少个单据就要在switch中添加多少项，这里单独抽离出来，方便一些，否则和jh.core揉在一起看着太多了
            // let comProp;
            switch (options.modelId) {
                case 'bill':
                    //这种方式可以正常的显示form表单，但是不能拿到组件的modelType，于是在dialogHelper那里就不能决定用哪种引擎，
                    //于是就不能显示引擎的东西，只有一个表单。
                    // comProp = defineAsyncComponent(() =>
                    //     importGuid('../views/project/demo/bill/BillCard')
                    // )

                    //下面这种方式其实就是得到组件的实例信息，可以拿到modelType和访问组件里面的方法。
                    //把路径单独提出来是为了以后可以通过字符串传入组件路径做铺垫
                    const comPath='views/project/demo/bill/BillCard';
                    import(`../${comPath}`).then(component => {
                        resolve(component.default);
                    });
                    break;
            }
            // return comProp;
        });
    }
    // 后端menu菜单中的path对应了page，而path必须和controller中的requestMapping保持一致，
    // 在生成代码的时候，对于单据而言，单据的billType其实就是controller中的requestMapping，
    // 所以这三者是一样的，我们就可以通过billType来找到单据的页面地址了。额外说明一下，菜单
    // 的page有可能是List页面，也可能是Card页面，所以我们下方就截取了单据的目录，然后手动的去
    // 构造出单据的页面，如果某个特殊的模块不是这种规则，或者页面被改了名称，那么可以在这里判断以下，
    // 然后单独处理，所以一般情况建议不要去改生成好的单据文件名称，特别是单据页面。
    static getComponentByBillType(options?:any):Promise<any>{
        const billType=options.modelId;
        return new Promise((resolve,reject) =>{
            //所有单据和单据对应的绝对路径
            let billPages=JSON.parse(UtilPub.decrypt(sessionStorage.getItem('billPages') || ''));
            //得到billType单据的菜单绝对路径
            let billPage=billPages[billType];
            //得到billType单据的菜单目录
            let billPageDir= billPage.substr(0,billPage.lastIndexOf('/')+1);
            //将billType首字母大写
            let upFirstBillType=billType.substring(0, 1).toUpperCase() + billType.substring(1);
            //构造单据页面(按代码生成规则组装单据页面：billType+List  billType+Card)
            let billCard=upFirstBillType+'Card';
            //单据的完整绝对路径
            billCard='views'+billPageDir+billCard;
            //注意：这里是按照代码生成规则的方式来构造单据页面的路径，如果你手动改了单据页面名称，
            // 那么就有可能找不到页面了。这个时候你就只有根据传过来的modelId手动构造单据页面的路径，
            // 参照上面的buildComponent。
            import(`../${billCard}`).then(component => {
                resolve(component.default);
            });
        });
    }
}