import {reactive, toRefs, onMounted,onBeforeMount,getCurrentInstance, provide, nextTick, defineComponent, computed} from 'vue'
import DialogUtil,{IDialogDataObj} from "@/components/base_comp/dialog/dialogUtil";
const DialogHelper = defineComponent({
    name: "Dialog",
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;//当前dialog组件实例代理
        provide('dialogInst', proxy);
        const utils=proxy.utils;
        //外界调用DialogApp的create方法传入的参数，比如列表引擎打开dialog的时候，调用showCard传给dialog的属性
        let engineParams=proxy.$params;
        let dataObj:IDialogDataObj=reactive<IDialogDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            customClass:engineParams.dialogDiv+"_class",//dialog的自定义class，方便关闭的时候从body上移除
            modelType:'',
            engineRef:null,//当前dialog包含的组件的ref
            engine:null,//当前dialog包含的组件
            engineParams:engineParams,//外界调用dialog的参数，此参数将传给引擎
            fullscreenLoading: false,//是否显示正在加载loading对话框
            options: {
                title: proxy.$t('tools.tip'),//dialog标题
                dWidth: "60%",//dialog内容默认宽度
                initHeight:'',//打开弹出框时，弹出框内容自适应之后的高度（存起来，恢复常态的时候好把弹出框高度设置为这个高度，否则到时候不知道设置多高）
                contentHeight: '',//dialog内容默认高度
                //animate.css动画延迟执行时间（如果启用dialog打开或关闭动画，那么就要设置延迟执行才能看到animate.css动画效果，
                //否则两者同时执行，当dialog动画完成之后，animate.css动画也完成了，这样就看不到animate.css动画效果了，
                // 由于dialog打开动画是1s，所以animate.css动画延迟执行1s最为合适）
                animationDelay:'0s',
                animationDuration:'0.8s',//animate.css动画持续时间
                overflow:'auto',//内容超高是否显示滚顶条
                showFooterBtn: false,//是否显示dialog底部的确认和关闭按钮
                fullscreen: true,//是否全屏显示dialog
                closeModel: false,//点击蒙层是否就关闭dialog
                showMaxRecover: true//是否显示最大化和恢复
            },
            dialogVisible: false,//dialog是否影藏
            otherParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DialogUtil(proxy,dataObj);
            //弹出框打开关闭动画，动态的传递旋转角度等参数到css中
            utils.AnimateUtil.dialogOpenCloseAnimationParams();
            //如果启用了弹出框动画,则需要把内容动画延后1s执行,否则看不到内容动画,因为弹出框动画执行需要1s,为了延续，我把等待时间故意提前0.4s
            if(utils.Tools.isAnimationOpen('dialogAnimation'))dataObj.options.animationDelay='0.6s';
        })
        onMounted(()=>{
            //处理弹出框背景图片
            nextTick(async()=>{
                dataObj.utilInst.initDialogParams(engineParams);//初始化dialog的一些参数，包括标题，是否全屏
                dataObj.utilInst.buildDialogContain(engineParams);//构建dialog包含的组件
                utils.AnimateUtil.dialogBgAnimate(dataObj.otherParams.showFooterBtn,utils);//弹出框背景图片动画
            })
        })
        //动态css。
        //先把一个数组里面全部填充为false，然后随机生成一个true，这样就只会启用一个动画css了
        const dialogContentClass=computed(()=>{
            return utils.AnimateUtil.dialogFormAnimate();
        })
        //弹出框底部确认、取消处理动作处理
        const bottomHandler=async (operType:string)=>{
            //如果调用dialog的组件传入了ownerComp（一般表示调用dialog的组件），而且调用dialog的组件定义有operType类别的方法，
            //即cancelHandler或者sureHandler方法； 则调用它，方法必须有返回值
            if (engineParams.ownerComp && engineParams.ownerComp[operType]){
                let result=await engineParams.ownerComp[operType](dataObj.engineRef);//把dialog包含的组件作为参数传给方法
                if(result)dataObj.dialogVisible=false;//不能自己调用doClose方法，否则会出问题，还是交给elementUi自己去关闭吧
            }else{
                dataObj.dialogVisible=false;//不能自己调用doClose方法，否则会出问题，还是交给elementui自己去关闭吧
            }
        }
        //关闭动作处理
        const doClose=()=>{
            document.body.removeChild(document.getElementById(engineParams.dialogDiv) as any);
            let divs:any=document.getElementsByClassName(engineParams.dialogDiv+"_class");
            for(let i=0;i<divs.length;i++){
                if(divs[i].parentNode.parentNode)document.body.removeChild(divs[i].parentNode.parentNode as any);
            }
            if (engineParams.ownerComp && engineParams.ownerComp['cancelHandler']){
                engineParams.ownerComp['cancelHandler'](dataObj.engineRef)
            }
        }
        //dialog关闭事件
        const close=()=>{}
        //dialog关闭动画结束事件，在close后执行
        const closed=()=>{doClose();}
        //dialog打开事件
        const open=()=>{}
        //dialog打开之后，但还未显示给用户看时的事件,在open之后执行
        const opened=async()=> {
            //记录下内容的初始化高度，当用户点击最大化按钮把窗口变大之后，然后再点击恢复常态按钮，这个时候应该把弹出框设置为打开弹出框时的高度，那个时候就用到存起来的变量了
            //至于为何要加5，很奇怪，测试的时候，其它模块没有发现异常，但是部门模块，恢复常态之后老是出现滚动条，于是我就把高度加了5个像素
            dataObj.options.initHeight=(dataObj.refMap.get('content').offsetHeight+5)+'px';
        }
        //最大化
        const maxHandler=()=>{
            let contentHeight=window.innerHeight-74;
            if(dataObj.options.showFooterBtn)contentHeight=contentHeight-(3+32+20);//(.el-dialog__footer padding-top:3 height:32 padding-buttom:20)
            dataObj.options.contentHeight=contentHeight+'px';
            dataObj.options.fullscreen=true;
            dataObj.utilInst.cfgListEngineContentHeight(true);
        }
        //恢复常态
        const recoveryHandler=()=>{
            dataObj.options.fullscreen=false;
            dataObj.options.contentHeight=dataObj.options.initHeight;
            dataObj.utilInst.cfgListEngineContentHeight(true);
        }
        return{
            ...toRefs(dataObj),doClose,close,closed,open,opened,dialogContentClass,bottomHandler,maxHandler,recoveryHandler
        }
    },
    components: {}
});
export default DialogHelper;