// @ts-ignore
import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, Router, RouteRecordRaw} from 'vue-router';
import utils from '@/utils/Jh.core';
import store  from '../store/';
import config from "@/utils/config";
import otherRoute from "@/router/otherRoute";
import Event from "@/views/sysviews/config/business/event"
//异步加载组件就是在定义组件的时候什么都不做，只有当组件第一次使用的时候才进行加载和渲染
const Welcome = () => import('@/views/sysviews/welcome/Welcome.vue');
//如果不声明下方的代码，那么window['routeMap']要报错
declare global {
    interface Window {
        customEvent: Array<any>;
        routeMap: Map<string, any>;
    }
}
let userRoutes:Array<RouteRecordRaw> = [
    {path: '/login', name: 'login', component: () => import('@/views/login/Login.vue')},
    {path: '/lockScreen', name: 'lockScreen', component: () => import('@/views/sysviews/yunwei/lockScreen/LockScreen.vue')},
    {path: '/',name: 'Main',redirect: '/welcome',meta: {icon: 'el-icon-message', title: '主界面'},component: () => import('@/components/layout/Main.vue'),children: []},
    {path: '/404', name: '404', component: () => import('@/views/sysviews/404/404.vue')},
    {path: '/register', name: 'register', component: () => import('@/views/sysviews/register/Register.vue')},
    {path: '/layer', name: 'layer', component: () => import('@/views/sysviews/layer/Layer.vue')}
];
if(otherRoute && otherRoute.length>0)userRoutes=userRoutes.concat(otherRoute);

class SystemRouter {
    public static buildRoutes(userMenus:any,routeMap:Map<string,any>):void{
        for (let i = 0; i < userMenus.length; i++) {
            if (userMenus[i].children) {
                SystemRouter.buildRoutes(userMenus[i].children, routeMap);//如果不是叶子节点，递归调用
            } else {
                let routItem =SystemRouter.buildLeafRouteItemInfo(userMenus[i]);//根据叶子菜单构建出路由对象信息
                routeMap.set(routItem.path, routItem);//把所有动态路由放一份到map集合中
                // @ts-ignore
                userRoutes[2].children.push(routItem);//把所有动态路由全部添加到/中作为二级路由
            }
        }
    }
    public static buildLeafRouteItemInfo(options:any):any{
        let routeItem:RouteItem = {path: options.path, name: options.id,meta:{},component: {}};
        let meta:Meta= options.meta;
        routeItem.meta=meta;//把菜单信息放到meta对象中
        if (options.page && !options.page.startsWith("http")){//为路由对象创建组件属性
            if(options.page=='/listEngine/ListEngine'){//直接请求的Engine，不在views目录下
                routeItem.component = async () => await import(`@/components/base_comp${options.page}`);
            }else{
                routeItem.component = async () => await import(`@/views${options.page}`);
            }
        }else{//page如果以http打头表示是链接,本来不需要组件的，但是vue3，你不创建组件要报错，所以这里就用一个辅助组件来帮忙
            routeItem.component = async () => await import('@/components/base_comp/Util.vue');
        }
        return routeItem;
    }
    public static buildPortalsToRoute(portals:any,routeMap:Map<string,any>):any{
        let portalComponents:any = {};
        portals.forEach(function (item:any) {
            portalComponents[item.id] = SystemRouter.getComponentByPage(item.page)
        }, this);
        let welComeRoute = {//构建welcome路由
            path: '/welcome',
            name: 'welcome',
            meta: {icon: 'el-icon-menu', title: utils.Tools.ctx.$t('tab.homePage')},
            components: Object.assign({}, {default: Welcome}, portalComponents)
        };
        routeMap.set(welComeRoute.path, welComeRoute);
        // @ts-ignore
        userRoutes[2].children.push(welComeRoute);
        //上面把所有动态路由已经放入到了routeMap中了，以下是放最上方定义的固定路由，经过这样的步骤，routeMap中就有所有的路由信息了，包含一级、二级路由
        userRoutes.forEach(item=>routeMap.set(item.path, item));
    }
    public static getComponentByPage(page:string):any{
        return () => import(`@/views${page}`);
    }
}

let router:Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: userRoutes
});

let excludePath=config.clientPathArr?config.clientPathArr:[];
//路由守卫，全局拦截。
router.beforeEach(async (to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext) => {
    if(excludePath.indexOf(to.path)==-1 && !to.path.endsWith('.html')){//excludePath数组里面的path在刷新或者访问的时候，不需要作验证，直接放行
        let lockScreen:ILockScreen=JSON.parse(sessionStorage.getItem('lockScreen') as string);
        if(lockScreen && lockScreen.lockScreen==true && ['/lockScreen','/login','/register'].indexOf(to.path)==-1){
            next('/lockScreen');//如果当前是锁屏状态，则需要再次进入路由守卫，直到进入锁屏路由，然后放行
        }else{
            if (to.path == '/login' || to.path == '/register') {
                //如果当前访问/login或者/register，需要判断是否登录过了，如果没有登录，则放行，让其访问/login或者/register；
                //如果登录过了，则需要再次路由守卫，直到找到路由/，然后放行
                sessionStorage.getItem("userInfo") == null?next():next('/');
            } else {
                //如果不是访问/login或者/register，首先就要判断是否登录，没有登录，则提示需要登录，然后再次进入路由守卫，直到找到路由/login，然后放行
                if (sessionStorage.getItem("userInfo") == null) {
                    utils.Tools.info({message: utils.Tools.ctx.$t('login.loginNote')});
                    next('/login');
                } else {
                    //如果已经登录过了，需要看当前是否刷新，刷新的时候，往Main下添加的子路由会消失，所以需要再次请求后台，构建路由信息
                    let children:any=router.options.routes[2].children;
                    if (children.length == 0) {
                        await utils.Api.loadUserMenus().then(res => {
                            store.commit("setNeedCorp", res.needCorp);
                            store.commit("setNeedDept", res.needDept);
                            store.commit("setNeedPerson", res.needPerson);
                            store.commit("setUseDataLevel", res.useDataLevel);
                            store.commit("setUploadSaveType", res.uploadSaveType);
                            store.commit("setMsgReceivePermission", res.hasMessageReceivePermission);
                            store.commit("setChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);
                            let userMenus = JSON.parse(res.userMenus);//用户菜单对象
                            let portals = res.portals;//可用的portal地址集合
                            let routeMap=new Map<string,any>();
                            //重新构造Main下的子路由信息
                            SystemRouter.buildRoutes(userMenus,routeMap);
                            SystemRouter.buildPortalsToRoute(portals,routeMap);
                            window['routeMap']=routeMap;
                            routeMap.has(to.path) ? next(to.path) : next('/404');
                        }).catch((err:any)=>{
                            utils.Tools.info({message: utils.Tools.ctx.$t('networkWrong')});
                            sessionStorage.removeItem(utils.Const.jfAccessToken);
                            sessionStorage.removeItem("userInfo");
                            location.reload();//由于userInfo被去除了。所以reload整个页面会在路由拦截那里经过判断，然后跳转到登录页
                        });
                        Event.regEvent();
                    }
                    if(to.fullPath!=store.getters.curRoute){//为了避免to:/路由A from:/路由A 这种无限循环，这里加入判断
                        store.commit("setCurrentRoute", to.fullPath);
                        //把新构建的路由动态添加到路由表中
                        userRoutes.forEach(item=>router.addRoute(item));
                        //动态添加完路由之后，再次进入路由守卫，直到找到要去的路由，然后放行，放行是最后的next()
                        next({...to, replace: true });
                    }else{
                        next()//放行出口
                    }
                }
            }
        }
    }else{
        next()//放行出口
    }
})
export default router;