import EventHelper from "@/views/sysviews/config/business/eventHelper";
import EventComponent from "@/views/sysviews/config/business/eventCompInf";

export default class Event{
    //---------------------------------------------触发事件-------------------------------（引擎调用）
    static async dispatchEvent(eventName:string,params:any):Promise<any>{
        return new Promise((resolve, reject) => {
            //由于触发事件是async，所以调用的地方加了await，如果不存在事件，在调用这里就不resolve的话，那么调用的地方就会一直等待；
            //所以下方加入判断，无论如何都要resolve，保证调用的地方可以继续执行后续代码
            if(window.customEvent.indexOf(eventName)>-1){//有注册事件
                document.dispatchEvent(new CustomEvent(eventName, {
                    detail: Object.assign({
                        afterEvent:(result:any)=>{
                            resolve(result);
                        }
                    },params)
                }));
            }else{//摸鱼注册事件
                resolve(true);
            }
        });
    }
    //---------------------------------------------注册事件-------------------------------（router/index.ts调用）
    static async regEvent():Promise<void>{
        window.customEvent=[];//把注册的自定义事件放到window的customEvent属性里面，这里是初始化它
        let items:any=EventHelper.modelAndInf();
        //注册模块要用到的事件
        for(let i=0;i<items.length;i++){
            items[i].infs.forEach((item:any)=>{
                let eventName:string=items[i].path+'_'+item;
                window.customEvent.push(eventName);//tmd，js没有接口获取已经注册的自定义事件，所以自己维护一份放到window对象身上
                document.addEventListener(eventName, async (event:any)=> {
                    await EventHelper.dealEvent({eventName:eventName,event:event});
                });
            })
        }
    }

    /**
     * 组件处理。比如数据格式化处理、事件绑定、为组件传递额外的属性等等（eventCardBillHelper.ts、eventListHelper.ts组件处理的时候调用）
     * comps-组件集合
     * modelName-模块名称
     * typeName-表单的时候等于常量form，明细表格的时候等于具体的明细表格key
     * */
    static bindCompPropEvent(comps:Array<any>,modelName:string,typeName:string):void{
        comps.forEach((item:any)=>{
            if(item.type=='select' && item.data){//找到要格式化数据的下拉组件
                if((EventComponent as any)[modelName+'_selectOnChange'])item.event=(EventComponent as any)[modelName+'_selectOnChange'];
                item.data.forEach((item:any,index:number,arr:any)=>arr[index]={value:item.id,label:item.name,item:item});
                item.bakData=item.data;
            }
            if(item.type=='comboGrid') {//找到下拉表格组件(参数和绑定的事件)
                item.attrs={
                    params: {comboId:item.prop,modelMethod:item.modelMethod}
                }
                if((EventComponent as any)[modelName+'_comboGridChange']){
                    item.attrs.bindEvent={comboGridChange:(EventComponent as any)[modelName+'_comboGridChange']}
                }
            }
            if(item.type=='comboTree') {//找到下拉树组件(参数和绑定的事件)
                item.attrs={
                    params: {comboId:item.prop,modelMethod:item.modelMethod}
                }
            }
        })
    }
}