import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue';
export interface IComboGridDataObj {
    utilInst:ComboGridUtil
    refMap:Map<string,any>
    visible:boolean//popover的显示/影藏控制字段
    readOnly:boolean//输入框是否只读（是否可以搜索）
    txtField:string//下拉框显示字段名称，默认列F_NAME
    idField:string//下拉框值字段名称，默认列F_ID
    comboText: string//下拉显示文本
    searchFieldsArray:Array<any>//参与搜索的字段
    gridParam: any//下拉框中表格的参数
    comboParam:any
    otherParams:any
}
export default class ComboGridUtil extends BaseBean{
    public dataObj:IComboGridDataObj
    public props:any;
    public context:any;
    constructor(proxy:any,dataObj:IComboGridDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //为dataObj相关属性赋值
    public async init(comboParam:any,modelValue:any):Promise<void>{
        //如果调用层传入了值字段和文本字段，就用调用层指定的，没有传入就用默认的
        if(comboParam.idField)this.dataObj.idField=comboParam.idField;
        if(comboParam.txtField)this.dataObj.txtField=comboParam.txtField;

        if(comboParam.searchFieldsArray){//如果自定义了搜索字段就采用自定义的，没有的话就采用表格所有字段参与搜索
            this.dataObj.searchFieldsArray=comboParam.searchFieldsArray;
        }else{//得到表格所有字段
            this.dataObj.searchFieldsArray=this.dataObj.refMap.get('gridTable').tbCols.fields;
        }
        this.dataObj.gridParam.queryParam.searchFieldsArray=this.dataObj.searchFieldsArray;
        if(comboParam.readOnly)this.dataObj.readOnly=comboParam.readOnly;
        // if(comboParam.multiple)this.dataObj.readOnly=true;//如果是多选下拉框，则不允许下拉框搜索
        //如果下拉框有值，但是没有显示文本，则发送请求到后台，用值取查询显示文本
        if(comboParam.modelMethod && modelValue && !this.dataObj.comboText)await this.getTextById(modelValue,comboParam);
    }
    //下拉框值改变
    public async comboGridValueChange(newValue:any,oldValue:any,modelValue:any,comboParam:any,context:any):Promise<void>{
        //第一次进入的时候newValue为null，可以通过这点来判断以下，刚进入不需要验证;
        if(newValue){
            //在流程设计的时候，条件下拉框那里，一条分支设置过条件，打开另外一条分支不设置条件，
            //然后在打开设置过条件的下拉框，就会出现下拉框有value，但是text没有了，所以要做如下的判断
            if(comboParam.modelMethod && !this.dataObj.comboText)await this.getTextById(modelValue,comboParam);
            // proxy.$parent.$parent.validate(newValue);//验证所有字段
            //有可能在明细中也用了下拉，那么就没有formRef了，所以这里要判断一下，只针对form表单的下拉控件进行验证
            if(comboParam.formRef)await comboParam.formRef.validateField(this.dataObj.comboParam.comboId);//值验证该控件id的字段
        }else{
            this.dataObj.comboText = '';
        }
        //刚进入页面的时候，newValue为null，不用触发值改变事件
        if(null!=newValue)context.emit('change', newValue, oldValue);
    }
    //根据下拉框的值找出该值的显示文本
    public async getTextById(modelValue:any,comboParam:any):Promise<void>{
        if (!modelValue)return ;
        if (comboParam.multiple) {
            if(comboParam.mulComboText)this.dataObj.comboText = comboParam.mulComboText;//对于多选的下拉框，text文本是从后台查询构造好了发送到前台的。
        } else {
            //不是多选的下拉框，显示的文本是根据id动态查询出来的
            if(comboParam.content){//如果是静态数据下拉框，就循环下拉框的内容找到要显示文本，不用去后台根据值查询显示文本
                let item=comboParam.content.find((row:any) => row[this.dataObj.idField] == modelValue);
                if(item)this.dataObj.comboText = item[this.dataObj.txtField];
            }else{//不是静态数据下拉框
                //在一个页面有很多下拉框的时候，如果后台把下拉框值对应的文本一次性的查询出来传递给前台会节约很多的资源，不会发多次请求了
                //当然，如果你不传递给前台也可以，就走else，一条一条的到数据库查询
                if(comboParam.comboText){
                    this.dataObj.comboText = comboParam.comboText;
                }else{
                    await nextTick(async ()=>{//tmd，有可能utils为空，还没有初始化好
                        //如果没有传入下拉框值对应的显示文本，则需要根据下拉框值去数据库找该值对应的显示文本
                        //下拉框请求路径配置的是：模块/url/combo，我们要取出模块url
                        let modelPathArr=comboParam.modelMethod.split('/');
                        let modelPath="/"+modelPathArr[1];
                        let params= Object.assign({},comboParam,{id: modelValue,comboType:'comboGrid',operateType:'getTextById'});
                        //这里其实可以直接请求load方法，只不过不是每个下拉框的显示字段都叫做name，所以干脆在后台抽出一个getTextById来处理显示文本，也可以在这里处理特殊任务
                        const res=await this.utils.Api.postRequest({url: modelPath + "/custom",params: params});
                        if(res.result)this.dataObj.comboText = res.comboText;
                    })
                }
            }
        }
    }
    //取消选择之后，处理下拉框显示的文本和下拉框的值
    public dealCancel(row:any,updateKey:string):void{
        let nowValue:any=this.props.modelValue;
        if(this.dataObj.comboText.indexOf(row[this.dataObj.txtField])>-1){
            if(this.dataObj.comboText==row[this.dataObj.txtField]){//只有一个
                this.dataObj.comboText='';
            }else if(this.dataObj.comboText.endsWith(row[this.dataObj.txtField])){//逗号向前
                this.dataObj.comboText = this.dataObj.comboText.replace(new RegExp(','+row[this.dataObj.txtField]),'');
            }else{//逗号向后
                this.dataObj.comboText = this.dataObj.comboText.replace(new RegExp(row[this.dataObj.txtField]+','),'');
            }
        }
        if(nowValue.indexOf(row[this.dataObj.idField])>-1){
            if(nowValue==row[this.dataObj.idField]){//只有一个
                this.context.emit(updateKey,'');
            }else if(nowValue.endsWith(row[this.dataObj.idField])){//逗号向前
                nowValue = nowValue.replace(new RegExp(','+row[this.dataObj.idField]),'');
                this.context.emit(updateKey,nowValue);
            }else{//逗号向后
                nowValue = nowValue.replace(new RegExp(row[this.dataObj.idField]+','),'');
                this.context.emit(updateKey,nowValue);
            }
        }
    }
    public async emitEvent(eventName:string,...params:any):Promise<void>{
        //为每个事件添加comboId传出去
        if(params)params[params.length]=this.dataObj.comboParam.comboId;
        else params[0]=this.dataObj.comboParam.comboId;
        //有些地方的事件是通过属性bindEvent的方式传进来的，比如后台生成前端代码的时候，在业务模块处用js就是这样绑定的，
        //当然建议有条件的情况下，还是用@事件名的方式来绑定事件吧，下方就是对这两种方式的处理
        if(this.props.bindEvent[eventName]){
            if(params)await this.props.bindEvent[eventName](...params);
            else await this.props.bindEvent[eventName]();
        }else{
            if(params) this.context.emit(eventName,...params);//触发事件
            else this.context.emit(eventName);//触发事件
        }
    }
}