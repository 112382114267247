import BaseBean from "@/utils/BaseBean";

export interface IComboTreeDataObj {
    utilInst:ComboTreeUtil
    refMap:Map<string,any>
    visible:boolean//popover的显示/影藏控制字段
    loading:boolean//加载效果
    readOnly:boolean//输入框是否只读（是否可以搜索）

    comboText: string//下拉显示文本
    mapTreeValueText:Map<string,any>//有复选框的时候，key为树节点的值，value为树节点的显示文本
    isLoaded: boolean//预防下拉框值改变不停的去后台查询的变量标志
    modelMethod:string//下拉的请求路径
    data: undefined,//树的数据集
    defaultProps:any
    baseParams:any//默认的下拉框参数
    comboParam:any
    otherParams:any
}
export default class ComboTreeUtil extends BaseBean{
    public dataObj:IComboTreeDataObj
    public props:any
    public context:any

    constructor(proxy:any,dataObj:IComboTreeDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj
        this.props=props
        this.context=context
    }
    public init(comboParam:any):void{
        Object.assign(this.dataObj.baseParams,comboParam);//把外界传入的参数全部合并到dataObj.baseParams中
        if(this.dataObj.baseParams.readOnly)this.dataObj.readOnly=this.dataObj.baseParams.readOnly;
        if(this.dataObj.baseParams.defaultProps)Object.assign(this.dataObj.defaultProps,this.dataObj.baseParams.defaultProps);
        if(this.dataObj.baseParams.showCheckbox)this.dataObj.baseParams.expandAll=true;
    }
    //根据下拉框的值找出该值的显示文本
    public async getTextById(modelValue:any,comboParam:any):Promise<void>{
        if (this.utils.UtilPub.isNotEmpty(modelValue)) {
            if(this.dataObj.baseParams.comboText){
                this.dataObj.comboText = this.dataObj.baseParams.comboText;
            }else{
                let modelPath=this.dataObj.modelMethod+"/custom";
                let params= Object.assign({},comboParam,{id:modelValue,comboType:'comboTree',operateType:'getTextById'});
                //这里其实可以直接请求load方法，只不过不是每个下拉框的显示字段都叫做name，所以干脆在后台抽出一个getTextById来处理显示文本，也可以在这里处理特殊任务
                const res=await this.utils.Api.postRequest({url: modelPath,params: params});
                if(res.result)this.dataObj.comboText = res.comboText;
            }
        }
    }
    public async emitEvent(eventName:string,...params:any):Promise<void>{
        //为每个事件添加comboId传出去
        if(params)params[params.length]=this.dataObj.comboParam.comboId;
        else params[0]=this.dataObj.comboParam.comboId;
        //有些地方的事件是通过属性bindEvent的方式传进来的，比如后台生成前端代码的时候，在业务模块处用js就是这样绑定的，
        //当然建议有条件的情况下，还是用@事件名的方式来绑定事件吧，下方就是对这两种方式的处理
        if(this.props.bindEvent[eventName]){
            if(params)await this.props.bindEvent[eventName](...params);
            else await this.props.bindEvent[eventName]();
        }
        else{
            if(params) this.context.emit(eventName,...params);//触发事件
            else this.context.emit(eventName);//触发事件
        }
    }
}