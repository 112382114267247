// @ts-ignore
import ElementPlus,{ElDatePicker,ElInput,ElSelect,ElTableColumn,ElCascader} from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import enLang from './lang/en'
import zhLang from './lang/zh'
import { createI18n } from 'vue-i18n'
// @ts-ignore  (这tmd还不能省略，否则运行要报错)
const currentLang:string=localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN';

// @ts-ignore
const i18n = createI18n({
    legacy:false,
    locale: currentLang,
    global:true,
    globalInjection: true, // 全局注入 $t 函数
    silentTranslationWarn: true, // 去掉警告
    messages: {
        // 'zh-CN':  require('./lang/zh.json'),
        // 'en': require('./lang/en.json')
        'zh-CN': zhLang,
        'en': enLang
    }
})
//修改ElementPlus组件的默认属性
//日期范围默认属性修改
ElDatePicker.props.startPlaceholder = { type: String, default: '开始日期'};
ElDatePicker.props.endPlaceholder = { type: String, default: '结束日期'};
ElDatePicker.props.rangeSeparator = { type: String, default: '至'};
ElDatePicker.props.valueFormat = { type: String, default: 'YYYY-MM-DD'};
ElDatePicker.props.style = { type: String, default: 'width:100%'};

// ElInput.props.clearable.default=true;//不能添加，添加了之后combogrid、combotree就有两个清除图表了
ElInput.props.showWordLimit.default=true;
//修改TableColumn：值对齐居中
ElTableColumn.props.align={type:String, default: "center"};
//值溢出的时候用...代替
ElTableColumn.props.showOverflowTooltip={type:Boolean, default: true};

ElSelect.props.filterable={type:Boolean, default: "true"};

//修改Cascader：默认可以清空、可以搜索、选中只展示最后一级
ElCascader.props.clearable={type:String, default: "true"};
ElCascader.props.filterable={type:String, default: "true"};
ElCascader.props.showAllLevels.default=false;
ElCascader.props.props={type:Object, default: ()=>{
    return{
        checkStrictly: true,emitPath:false
    }
}};
//如果ElCascader设置style：width=100%效果会出问题，但是ElDatePicker却没事，不知道为啥
// ElCascader.props.style = { type: String, default: 'width:100%'};

export default (app:any,ref:any) => {
    // ElementPlus.useLang(app, ref, zh)
    //组件英文
    // app.use(ElementPlus, {zIndex: 3000,en })
    //组件中文
    app.use(i18n);
    //elementuiPlus的语言包
    if(localStorage.getItem('currentLang')=='en'){
        app.use(ElementPlus, {zIndex: 3000,en });
    }else{
        app.use(ElementPlus, {zIndex: 3000,locale });
    }
    app.config.globalProperties['i18n'] = i18n;
    app.config.globalProperties['elementPlus'] = ElementPlus;
}
