import {Commit} from "vuex";

interface IOthersState {//state接口
    needDept: boolean,//是否需要部门
    needCorp: boolean,//是否需要机构
    needPerson: boolean,//是否需要人员
    useDataLevel: boolean,//是否启用数据权限控制
    uploadSaveType: number,//文件上传处理方式：0-自己处理上传 1-阿里云存储
    hasMessageReceivePermission:boolean,//是否具有接收消息的权限（是否显示top中消息中心）
    hasChangePersonalInfosPermission:boolean,//是否具有修改个人信息的权限（是否显示top中下拉：修改个人信息）
    curRoute:any//当前路由
}
let othersState:IOthersState = {//state初始化
    needDept: true,//是否需要部门
    needCorp: true,//是否需要机构
    needPerson: true,//是否需要人员
    useDataLevel: true,//是否启用数据权限控制
    uploadSaveType:0,//文件上传处理方式：0-自己处理上传 1-阿里云存储
    hasMessageReceivePermission:false,//是否具有接收消息的权限（是否显示top中消息中心）
    hasChangePersonalInfosPermission:false,//是否具有修改个人信息的权限（是否显示top中下拉：修改个人信息）
    curRoute:'',//当前路由
}
interface ActionContext{
    commit: Commit,
    state:IOthersState
}
export default {
    state:othersState,
    mutations: {
        ['setNeedCorp'](state:IOthersState, needCorp:boolean) {//设置是否需要机构
            state.needCorp = needCorp;
        },
        ['setNeedDept'](state:IOthersState, needDept:boolean) {//设置是否需要部门
            state.needDept = needDept;
        },
        ['setNeedPerson'](state:IOthersState, needPerson:boolean) {//设置是否需要个人
            state.needPerson = needPerson;
        },
        ['setUseDataLevel'](state:IOthersState, useDataLevel:boolean) {//是否启用数据权限控制
            state.useDataLevel = useDataLevel;
        },
        ['setUploadSaveType'](state:IOthersState, uploadSaveType:number) {//文件上传处理方式：0-自己处理上传 1-阿里云存储
            state.uploadSaveType = uploadSaveType;
        },
        ['setMsgReceivePermission'](state:IOthersState, permission:boolean) {//设置是否具有接收消息的权限
            state.hasMessageReceivePermission = permission;
        },
        ['setChangePersonalInfoPermission'](state:IOthersState, permission:boolean) {//设置是否具有修改个人信息的权限
            state.hasChangePersonalInfosPermission = permission;
        },
        ['setCurrentRoute'](state:IOthersState, curRoute:any) {//设置当前路由
            state.curRoute = curRoute;
        }
    },
    actions: {
    },
    getters: {
        needDept: (state:IOthersState) => state.needDept,
        needCorp: (state:IOthersState) => state.needCorp,
        needPerson: (state:IOthersState) => state.needPerson,
        useDataLevel: (state:IOthersState) => state.useDataLevel,
        uploadSaveType: (state:IOthersState) => state.uploadSaveType,
        hasMessageReceivePermission: (state:IOthersState) => state.hasMessageReceivePermission,
        hasChangePersonalInfosPermission: (state:IOthersState) => state.hasChangePersonalInfosPermission,
        curRoute: (state:IOthersState) => state.curRoute
    }
}