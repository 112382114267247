import ListEngine from '@/components/base_comp/listEngine/ListEngine.vue';
// importGuid BaseReport from '@/components/base_comp/BaseReport';
// importGuid BaseBaiduMap from '@/components/base_comp/BaseBaiduMap';
// importGuid Dialog from '@/components/base_comp/Dialog';
import UploadComp from '@/components/base_comp/upload/UploadComp.vue';
import ComboGrid from '@/components/base_comp/comboGrid/ComboGrid.vue';
import ComboTree from '@/components/base_comp/comboTree/ComboTree.vue';
import GridTable from '@/components/base_comp/gridTable/GridTable.vue';
import EditTable from '@/components/base_comp/editTable/EditTable.vue';
import ImportGuid from '@/components/base_comp/importGuid/ImportGuid.vue';
import Split from '@/components/base_comp/split/Split.vue';
import Unit from '@/components/base_comp/unit/Unit.vue';
import CardForm from '@/components/base_comp/cardEngine/cardForm/CardForm.vue';
import EditTableItem from '@/components/base_comp/editTable/editTableItem/EditTableItem.vue';


export default (app:any) => {
  app.component('ListEngine', ListEngine);
  // app.component('BaseReport', BaseReport);
  // app.component('BaseBaiduMap', BaseBaiduMap);
  // app.component('Dialog', Dialog);
  app.component('UploadComp', UploadComp);
  app.component('ComboGrid', ComboGrid);
  app.component('ComboTree', ComboTree);
  app.component('GridTable', GridTable);
  app.component('EditTable', EditTable);
  app.component('ImportGuid', ImportGuid);
  app.component('Split', Split);
  app.component('Unit', Unit);
  app.component('CardForm', CardForm);
  app.component('EditTableItem', EditTableItem);
}