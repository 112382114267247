// const OSS = require('ali-oss');
import OSS from 'ali-oss';
import Tools from './Tools';
import UtilPub from './UtilPub';
import config from "@/utils/config";
import Api from "@/utils/api/Api";
//被注释掉部分是直接使用密钥上传，这样比较危险，暴漏在客户端了，因此每次上传的时候，请求了一下后台，获取临时密钥来上传

// const ossConfig = {
//     region: 'cn-shanghai',
//     accessKeyId: 'LTAI5tMF9QgmbLpRyf9kEDqW',
//     accessKeySecret: 'Qh6Kaid7QAvxtchpAunbbRll41tW7U',
//     bucket: 'shineray-dev-suxiang',
//     endpoint:'oss-cn-shanghai.aliyuncs.com'
// }
// let $$ossInst= new OSS({
//     region: ossConfig.region,
//     accessKeyId: ossConfig.accessKeyId,
//     accessKeySecret: ossConfig.accessKeySecret,
//     bucket: ossConfig.bucket,
//     endpoint: ossConfig.endpoint
// })
// $$ossInst.multipartUpload(uploadName, file, {
//     progress: function(p:any) {}//p进度条的值// that.showProgress = true;// that.progress = Math.floor(p * 100);
// }).then(async ({res, name}:{res:any,name:string}) => {
//     if (res && res.status == 200) {//console.log(`阿里云OSS上传图片成功回调`, res, url, name);
//     }
// }).catch((err:any) => {
//
// });

export default class AliOss{
    //上传组件上传文件到阿里云
    static async doUpload(params:any):Promise<any>{
        let res=await Api.getAliOssKey({});//从后台拿取凭证
        let ossInst= new OSS({
            region: res.region,
            accessKeyId: res.keyId,
            accessKeySecret: res.keySecret,
            stsToken: res.secretToken,//如果是采用临时key和secret的方式，那么就必须要有stsToken，否则上传会失败，如果不是临时的，则不用传这个参数
            bucket: res.bucketName,
            endpoint: res.endpoint
        })
        ossInst.multipartUpload(params.uploadName, params.file, {
            progress: function(p:any) {
                params.proxy.otherParams.uploadPercentage= Math.floor(p * 100);
            }//p进度条的值// that.showProgress = true;// that.progress = Math.floor(p * 100);
        }).then(async ({res, name}:{res:any,name:string}) => {
            if (res && res.status == 200) {//console.log(`阿里云OSS上传图片成功回调`, res, url, name);
                await params.proxy.utilInst.buildParamsYun(res,params.uploadName,params,params.loading);//调用上传组件uploadUtil那里的该方法
            }else{
                Tools.info({message: (res.msg?res.msg:"上传失败!")});params.loading.close();
            }
        }).catch((err:any) => {
            console.log(err)
            Tools.error({message:"上传至阿里云失败"+err});params.loading.close();
        });
    }
    //富文本组件上传文件到阿里云
    static async editorOssUpload(file:any,editor:any):Promise<any>{
        let res=await Api.getAliOssKey({});
        let ossInst= new OSS({
            region: res.region,
            accessKeyId: res.keyId,
            accessKeySecret: res.keySecret,
            stsToken: res.secretToken,//如果是采用临时key和secret的方式，那么就必须要有stsToken，否则上传会失败，如果不是临时的，则不用传这个参数
            bucket: res.bucketName,
            endpoint: res.endpoint
        })
        return new Promise((resolve, reject) => {
            let type=config.type?config.type:'product';
            let aliCloudPre=config.aliCloudPre?config.aliCloudPre:type+'EditorSource';
            let uploadName=`${aliCloudPre}/${type}`+'/editor/'+UtilPub.guid()+'-'+file.name;
            ossInst.multipartUpload(uploadName, file, {
                progress: function(p:number) {//p进度条的值
                    // that.showProgress = true;
                    // that.progress = Math.floor(p * 100);
                }
            }).then(async ({res, name}:{res:any,name:string}) => {
                if (res && res.status == 200) {//console.log(`阿里云OSS上传图片成功回调`, res, url, name);
                    let serverPath=res.requestUrls[0];
                    //tmd，有时候上传返回的结果后面带上了?uploadId
                    if(serverPath.indexOf('?')>-1)serverPath=res.requestUrls[0].substr(0,res.requestUrls[0].indexOf('?'));
                    editor.cmd.do('insertHTML', `<img src="${serverPath}" alt="">`);
                }else{
                    Tools.info({message: (res.msg?res.msg:"上传失败!")});
                }
                resolve(res);
            }).catch((err:any) => {
                console.log(`阿里云OSS上传图片失败回调`, err);
                reject({result:false});
            });
        })
    }
}