import EventCompInf from "@/views/sysviews/config/business/eventCompInf";
import utils from "@/utils/Jh.core";
import EventListHelper from "@/views/sysviews/config/business/eventListHelper";
import EventCardBillHelper from "@/views/sysviews/config/business/eventCardBillHelper";

export default class EventHelper {
    //要开发的模块和模块要注册的事件名称
    static modelAndInf():Array<any>{
        //所有的接口和模块组合在一起就是事件名称,下面这个数组是所有的可用接口（明细表格的一些接口还未包含进来）
        let eventInterface=['pageData','dealPageInfo',
            'addOrLoad','setParam','beforeOpen','loadDetails','afterOpened','saveHandler','setDetailsJson','beforeSaveHandler','afterSaveHandler','beforeDeleteHandler','afterDeleteHandler',
            'beforeEndEdit'];
        let models:any=[//所有的模块和模块可以使用的接口
            // {path:'/simpleCard',infs:['pageData','dealPageInfo','beforeOpen']},
            // {path:'/complexCard',infs:['pageData','dealPageInfo','beforeOpen']},
        ];
        return models;
    }
    //处理事件
    static async dealEvent(params:any):Promise<any>{
        let modelPath=params.eventName.split('_')[0];
        // switch (params.eventName) {
        //     case '/simpleCard_pageData'://进入列表页(卡片的参数也在这里指定，会放到列表引擎的listParam.compParams上面，然后传给卡片)
        //     case '/complexCard_pageData':
        //         EventListHelper.enterListEngine(params.event,utils.DemoProviderApi.buildUrl(modelPath+'/pageData'));
        //         params.event.detail.afterEvent(true);
        //         break;
        //     case '/simpleCard_dealPageInfo'://列表后台请求完毕
        //     case '/complexCard_dealPageInfo':
        //         EventListHelper.dealListPageInfo(params.event);
        //         params.event.detail.afterEvent(true);
        //         break;
        //     case '/simpleCard_beforeOpen'://打开卡片
        //     case '/complexCard_beforeOpen':
        //         EventCardBillHelper.dealCardBillPageInfo(params,{
        //             beforeEndEdit:(EventCompInf as any)['complexCard_beforeEndEdit']//即便没有明细，传了事件也没有问题
        //         });
        //         params.event.detail.afterEvent(true);
        //         break;
        // }
    }
}