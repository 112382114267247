import {nextTick} from "vue";

export default class BaseBean {
    public proxy:any;
    public utils:any;
    public store:any;
    constructor(proxy:any) {//比onMounted先执行
        nextTick(()=>{
            if(proxy){
                this.proxy=proxy;
                this.utils=proxy.utils;
                this.store=proxy.$store;
            }
        })
    }
}