import { createApp,ref } from 'vue'
import 'element-plus/dist/index.css'
import "./assets/iconfont/iconfont.css" ;
import App from './App.vue'
import installElementPlus from './plugins/element';
import installJhComponent from './plugins/jhComponent';
import utils from './utils/Jh.core';
import axios from "axios";
import store  from './store/';
import router from './router';
import config from './utils/config'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import globalTools from './utils/rule';
// @ts-ignore
import {export_json_to_excel,export_mul_head_excel} from './excel/Export2Excel.js';
import animated from "animate.css";
import addLangProps from './plugins/lang/lang';
(async () => {
    const app = createApp(App)
    utils.Tools.initCtx(app);
    app.use(store);//这是把store绑定到全局实例身上的一种方法，访问的时候要加$,比如proxy.$store
    app.use(router);
    app.use(animated);
    installElementPlus(app,ref);
    installJhComponent(app);
    config.bindAppProps(app,[
        {key:'$axios',value:axios},
        {key:'utils',value:utils},//这是把store绑定到全局实例身上的第二种方法，访问的时候不需要加$,绑定的时候是啥，访问的时候就是啥，拿这里的$axios和utils举例：proxy.$axios、proxy.utils
        {key:'excelUtils',value:export_json_to_excel},
        {key:'valid',value:globalTools.valid},
        {key:'addLangProps',value:addLangProps},
        {key:'exportMulHeadExcel',value:export_mul_head_excel}
    ]);
    await router.isReady();
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    app.mount('#app');
    // app._context.components//可以访问到挂在app上的所有组件
    //app._context.components.ElDialog["props"].closeOnClickModal.default = false//全局更改某个组件的默认属性
})();
