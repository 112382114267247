import E from "wangeditor";
import Editor from 'wangeditor';
import Const from "@/utils/Const";
import store from '@/store'
import AliOss from './aliyun.oss'
import config from "@/utils/config";
class NotificationContent {
    ctx:any
    title:string
    position:string= "bottom-right"
    message:string
    duration:number= 3000
    constructor(title:string,position:string,message:string,duration:number,ctx:any) {
        this.title=title;
        if(position)this.position=position;
        this.message=message;
        if(duration!=0)this.duration=duration;
        this.ctx=ctx;
    }
    getOptions():any{
        return{
            title:this.title?this.title:this.ctx.$t('tools.tip'),
            position:this.position,
            message:this.message?this.message:this.ctx.$t('tools.success'),
            duration:this.duration
        }
    }
}

export default class Tools {
    static ctx:any;
    //从vuex中获取属性值
    static getPropFromVuex(propName:string):any{
        return store.getters[propName];//如果页面在刷新还未呈现的时候，访问vuex会有问题,因为这个时候vuex的变量是默认值，可能还未从后台获取配置的值
    }
    //动画是否开启
    static isAnimationOpen(name:string):any{
        let result=(sessionStorage.getItem('animations') as string).indexOf(name)>-1;
        if(localStorage.getItem(name)){
            result=localStorage.getItem(name)=='true' || localStorage.getItem(name)=='1';
        }
        return result;
    }
    static initCtx(app:any):void{
        Tools.ctx=app.config.globalProperties;
    } 
    static success(options?:any):void{
        let notificationContent=new NotificationContent('','','',3000,this.ctx);
        let params = Object.assign({}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.success(params);
    }
    static warning(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.warn'),'','警告信息',3000,this.ctx);
        let params = Object.assign({}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.warning(params);
    }
    static error(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.fail'),'',this.ctx.$t('tools.failMsg'),3000,this.ctx);
        let params = Object.assign({ dangerouslyUseHTMLString: true}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.error(params);
    }
    static info(options?:any):void{
        let notificationContent=new NotificationContent(this.ctx.$t('tools.tip'),'','消息提醒',3000,this.ctx);
        let params = Object.assign({dangerouslyUseHTMLString: true}, notificationContent.getOptions(), options);
        Tools.ctx.$notify.info(params);
    }
    static configBox(options?:any):void{
        let title = Tools.ctx.$t('tools.tip');
        let message = Tools.ctx.$t('tools.deleteNote');
        let confirmButtonText = Tools.ctx.$t('confirm');
        let cancelButtonText = Tools.ctx.$t('cancel');
        let type = "warning";
        let distinguishCancelAndClose=false;
        let showCancelMsg=true;
        if (options && options.title) title = options.title;
        if (options && options.message) message = options.message;
        if (options && options.confirmButtonText) confirmButtonText = options.confirmButtonText;
        if (options && options.cancelButtonText) cancelButtonText = options.cancelButtonText;
        if(options.hasOwnProperty('distinguishCancelAndClose'))distinguishCancelAndClose=options.distinguishCancelAndClose;
        if(options.hasOwnProperty('showCancelMsg'))showCancelMsg=options.showCancelMsg;
        Tools.ctx.$confirm(message, title, {
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            dangerouslyUseHTMLString: true,
            distinguishCancelAndClose:distinguishCancelAndClose,
            type: type
        }).then(() => {
            if (options && options.sureFn) options.sureFn();
        }).catch((err:any) => {
            if(showCancelMsg)Tools.ctx.$message({type: 'info',message: Tools.ctx.$t('tools.cancelOperate')});
            if (options && err=='cancel' && options.cancelFn) options.cancelFn();
            if (options && err=='close' && options.closeFn) options.closeFn();
        });
    }

    static defDateRange():any{
        return[{
            text: '当天',
            value: new Date()
        },{
            text: '最近一周',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                return [start, end]
            })()
        }, {
            text: '最近一个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                return [start, end]
            })()
        }, {
            text: '最近三个月',
            value: (() => {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                return [start, end]
            })()
        }]
    }
    static buildWangEditorField(options?:any):Editor{
        options=Object.assign({uploadSaveType:Tools.getPropFromVuex('uploadSaveType'),height:200},options);
        // 配置 server 接口地址
        // const editField = new E('#'+options.fieldId);//不能用id来创建，需要用ref得到dom来创建，否则操作快一点会造成重复创建的错误，但是ref就不存在这个错误
        const editField = new E(options.editRef);
        if(options.uploadSaveType==1){
            editField.config.customUploadImg = async (files:any, insert:any) => {
                const res = await AliOss.editorOssUpload(files[0],editField)
            }
        }else{
            let uploadImgServer=config.baseURL;
            if(config.contextPath)uploadImgServer=uploadImgServer+config.contextPath;
            uploadImgServer+="/attach/uploadFiles";
            editField.config.uploadImgServer = uploadImgServer;
            editField.config.uploadFileName = 'commonFile';
            editField.config.uploadImgParams = {
                uploadType:'editorPic'
            }
            editField.config.uploadImgHeaders[Const.jfAccessToken]=sessionStorage.getItem(Const.jfAccessToken) as string
        }

        editField.config.zIndex = 300;
        editField.config.height = options.height;
        editField.create();
        editField.txt.clear();//销售单中，点击调价的时候，又会打开一个弹出框，这个时候会出现富文本编辑器重复嵌套的问题，这句话就是解决这个问题的
        return editField;
    }
}