import {Commit} from "vuex";
import {RouteLocationNormalizedLoaded} from "vue-router";
import utils from '@/utils/Jh.core';
interface IUserTabState {//state接口
    visitedTabsView:Array<any>
}
let userTabState:IUserTabState = {//state初始化
    visitedTabsView: []//目前已有的tabs
}
interface ActionContext{
    commit: Commit,
    state:IUserTabState
}


export default {
    state:userTabState,
    mutations: {
        ['addTabView'](state:IUserTabState, view:RouteLocationNormalizedLoaded) {
            if (state.visitedTabsView.find((n:RouteLocationNormalizedLoaded) => n.path === view.path))return;//如果要添加的tab已经打开添加过了，则直接返回
            state.visitedTabsView.push({ title: view.meta.title, path: view.path });//添加tab到数组中
        },
        ['closeTabView'](state:IUserTabState, view:any) {//清除指定的tab
            for (let [i, v] of state.visitedTabsView.entries()) {//循环已打开的tabs，找到要清除的tab，把它从已打开的tabs中清除掉
                if (v.path === view.path || v.title === view.title) {
                    state.visitedTabsView.splice(i, 1);break;
                }
            }
        },

        ['closeAllTabViews'](state:IUserTabState) {//清除所有的tab，然后添加welcome，相当于只保留welcome
            state.visitedTabsView.length=0;
            state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" })
        },
        ['recoverViews'](state:IUserTabState,viewArr:Array<any>) {//恢复welcome和viewArr集合的所有tab
            let exist:boolean=state.visitedTabsView.some((item:any)=>item.path==='/welcome');
            if(!exist)state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" });
            for (let i=0;i<viewArr.length;i++) {
                state.visitedTabsView.push({ title: viewArr[i].title, path: viewArr[i].path});
            }
        },
        ['closeOtherTabViews'](state:IUserTabState,view:any) {//除了当前tab和welcome之前，清除其它所有tabs
            state.visitedTabsView.length=0;//先清除所有的tabs，然后添加当前的tab到已访问的tabs数组中，如果当前tab不是welcome，还要把welcome也添加到已访问的tabs数组中
            if(view.path!='/welcome')state.visitedTabsView.push({ title: utils.Tools.ctx.$t('tab.homePage'), path: "/welcome" })
            state.visitedTabsView.push({ title: view.title, path: view.path });
        }
    },
    actions: {
        // 添加一个新的tabsView
        addVisitedTabsView({ commit }:ActionContext, view:RouteLocationNormalizedLoaded) {//当前的路由不是/则添加
            if(view.path!='/' && view.path!='/layer')commit('addTabView', view)
        },
        // 关闭一个tabsView
        closeSpeTabsView({ commit, state }:ActionContext, view:RouteLocationNormalizedLoaded) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    commit('closeTabView', view)
                    resolve([...state.visitedTabsView])//先展开，然后再加上[]，相当于复制一下visitedTabsView
                }
            })
        },
        //清除所有已经访问的tabs
        closeAllVisitedTabsView({ commit }:ActionContext,options:any) {
            commit('closeAllTabViews')
        },
        //清除除了welcome还有指定之外的所有已经访问了的tab
        recoverViews({ commit }:ActionContext, viewArr:Array<any>) {
            commit('recoverViews',viewArr)
        },
        //关闭其它tabs
        closeOtherTabsView({ commit }:ActionContext, view:RouteLocationNormalizedLoaded) {
            commit('closeOtherTabViews', view)
        },
        //检查指定路由是否在已显示的路由数组中
        checkTabsViewExist({ commit,state }:ActionContext, view:RouteLocationNormalizedLoaded) {
            return new Promise((resolve, reject) => {
                if(view.path!='/welcome'){
                    let exist=state.visitedTabsView.some((n:RouteLocationNormalizedLoaded) => n.path === view.path);
                    resolve(exist);
                }
            })
        },
        //改变selfName
        changeSelfName({ commit }:ActionContext, newName:string) {
            commit('changeSelfName', newName)
        }
    },
    getters: {
        //获取现在已有的tabs
        visitedTabsView: (state:IUserTabState) => state.visitedTabsView
    }
}